export const defaultSearches = [
  {
    tables: [
      {
        columns: [],
        filter: [],
        sorting: {
          key: '',
          direction: 0,
        },
        hiddenColumns: [],
      },
      {
        columns: [],
        sorting: {
          key: '',
          direction: 0,
        },
        filter: [
          {
            prop: {
              _id: '6234928d364ba800bd8a52d4',
              uuid: 'b828bd9f-23c1-4478-ba40-5e754e0d03a6',
              productUUID: '7878d6cb-3920-49bf-94f7-cbce9115e979',
              key: 'Mantelfarbe',
              value: 'Schwarz',
              providerUUID: 'ad34a6f9-d41a-4b1d-8604-811ab1e3c5c8',
              active: true,
              userUUID: 'crawler',
              createdOn: '2022-03-18T14:09:17.597Z',
              __v: 0,
            },
            searchString: 'schwarz',
          },
          {
            prop: {
              _id: '6234928d364ba800bd8a52d5',
              uuid: 'bbd0b731-7dca-4a92-8563-65971b98345d',
              productUUID: '7878d6cb-3920-49bf-94f7-cbce9115e979',
              key: 'Leiterquerschnitt',
              value: '95 mm²',
              providerUUID: 'ad34a6f9-d41a-4b1d-8604-811ab1e3c5c8',
              active: true,
              userUUID: 'crawler',
              createdOn: '2022-03-18T14:09:17.597Z',
              __v: 0,
            },
            searchString: 'mm',
          },
        ],
        hiddenColumns: [],
      },
    ],
    uuid: '90d0e7f6-484b-4ba6-bb21-fce674e36a02',
    name: 'test',
  },
]
