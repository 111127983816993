import * as types from '../type/types'

export const setProductLists = (productLists) => ({
  type: types.PRODUCT_LISTS,
  payload: productLists,
})

export const updateProductList = (productList, index) => ({
  type: types.UPDATE_PRODUCT_LIST,
  payload: { productList, index },
})

export const addProductList = (productList) => ({
  type: types.ADD_PRODUCT_LIST,
  payload: productList,
})

export const updateProductListItems = (items, index) => ({
  type: types.UPDATE_PRODUCT_LIST_ITEMS,
  payload: { items, index },
})
