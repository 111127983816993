import styled from 'styled-components'

export const StyledResult = styled.div``

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: 20px;
  margin-top: 20px;
`

export const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
`
export const CustomButton = styled.div`
  width: 20%;
`

export const Warning = styled.div`
  color: red;
  font-size: 12px;
  margin-bottom: 20px;
`
