import { useEffect } from 'react'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { updateProductListItems } from '../../../../../action/productLists'
import fetch from '../../../../../fetch'
import { frontendDomain, getPrettyDateTime, getUUID } from '../../../../../utility'
import NewEditModal from './EditModal/NewEditModal'
import './Item.scss'
import LinkBuilder from './LinkBuilder/LinkBuilder'

const Item = ({ isFirst, product, columns, excludedColumns, translations, producer, listIndex, hideButtons }) => {
  const [showProps, setShowProps] = useState(false)
  const [props, setProps] = useState(null)
  const [showModal, setShowModal] = useState(false)
  const [edits, setEdits] = useState(null)
  const user = useSelector((state) => state.user)

  const dispatch = useDispatch()

  const selectedProduct = useSelector((state) => state.selectedProductForComparison)

  const token = useSelector((state) => state.token)

  useEffect(() => {
    const getProps = async () => {
      const { status, props } = await fetch('/product/props/get', { producer, uuid: product.uuid, token })
      if (status && props) {
        setProps(props)
      }
    }

    const getEdits = async () => {
      const { status, result } = await fetch('/product/edits/get', { producer, productUUID: product.uuid, token })
      if (status && result) {
        setEdits(result)
      } else {
        setEdits([])
      }
    }

    if (showProps && !props) getProps()
    if (selectedProduct && !props) {
      getProps()
      setShowProps(true)
    }

    if (!showProps) setProps(null)
    if (!edits) getEdits()
  }, [producer, product, showProps, props, selectedProduct, edits, token])

  const onClickSelectProduct = async (prod) => {
    const getProps = async () => {
      const { status, props } = await fetch('/product/props/get', { producer, uuid: prod.uuid, token })
      if (status && props) {
        return props
      }
    }

    if (props) {
      prod.props = props
      dispatch(updateProductListItems([prod], listIndex))
    } else {
      prod.props = await getProps()
      dispatch(updateProductListItems([prod], listIndex))
    }
  }

  const handleModalClose = () => {
    setShowModal(false)
  }

  const onClickSave = (edits) => async () => {
    for (const propKey of Object.keys(edits)) {
      if (Array.isArray(edits[propKey].origin)) edits[propKey].value = edits[propKey].origin.length > 0 ? edits[propKey].value.split(';') : []
    }
    const { status } = await fetch('/product/edits/save', { edits, producer, token })
    if (status) {
      handleModalClose()
    }
  }

  return (
    <div className="listItem">
      <div className="productRow">
        <div className="columnWrapper">
          {columns &&
            edits &&
            Array.isArray(columns) &&
            columns
              .filter((c) => !excludedColumns.includes(c))
              .map((col) => {
                return (
                  <div key={getUUID()}>
                    {col === 'url' || col === 'dataSheetURL' || col === 'resourcesURLs' ? (
                      <LinkBuilder urls={product[col]} producer={producer} product={product} col={col} />
                    ) : (
                      <div
                        key={col}
                        className={
                          product.productComparisonResult
                            ? Object.keys(product.productComparisonResult).includes(col) && product.productComparisonResult[col].value
                              ? 'green'
                              : Object.keys(product.productComparisonResult).includes(col) && product.productComparisonResult[col].value === null
                              ? ''
                              : 'red'
                            : ''
                        }
                      >
                        {col === 'createdOn' ? getPrettyDateTime(new Date(edits[col] ? edits[col].value : product[col])) : edits[col] ? edits[col].value : product[col]}
                      </div>
                    )}
                  </div>
                )
              })}
        </div>
        <div className="itemBtnWrapper">
          <div className="buttonRow">
            <div className="itemButton" onClick={() => setShowProps(!showProps)}>
              show Props
            </div>
            {isFirst && !hideButtons && (
              <div className="itemButton" onClick={() => onClickSelectProduct(product)}>
                Auswählen
              </div>
            )}
          </div>
          <div className="buttonRow">
            {!hideButtons && (
              <>
                {user.permissions !== 'read' && (
                  <div className="itemButton" onClick={() => setShowModal(true)}>
                    Bearbeiten
                  </div>
                )}
                <a className="itemButton" href={`${frontendDomain}/history/${producer.uuid}/${product.uuid}`} target="_blank" rel="noreferrer">
                  History
                </a>
              </>
            )}
          </div>
        </div>
      </div>
      {showProps && props && Array.isArray(props) && (
        <div className="propsWrapper">
          <div className="labelProps">Eigenschaften:</div>

          <div>{product.description}</div>

          {props.map((prop) => (
            <div className="propRow" key={prop.key}>
              <div className={product.propsComparisonResult ? (Object.keys(product.propsComparisonResult).includes(prop.key) && product.propsComparisonResult[prop.key].key ? 'green' : 'red') : ''}>
                {translations && Array.isArray(translations) && translations.find((t) => t.key === prop.key) ? translations.find((t) => t.key === prop.key).translation : prop.key}
              </div>
              <div className={product.propsComparisonResult ? (Object.keys(product.propsComparisonResult).includes(prop.key) && product.propsComparisonResult[prop.key].value ? 'green' : 'red') : ''}>
                {edits[prop.key] ? edits[prop.key].value : prop.value}
              </div>
            </div>
          ))}
        </div>
      )}
      {showModal && <NewEditModal product={product} onClickSave={onClickSave} handleClose={handleModalClose} translations={translations} producer={producer} />}
    </div>
  )
}

export default Item
