import './Search.scss'
import SearchIcon from '@mui/icons-material/Search'
import { useDispatch, useSelector } from 'react-redux'
import { useState } from 'react'
import fetch from '../../../fetch'
import { set } from '../../../action/tables'
import { getColumns, getSortedColumns } from '../../../helper/columns'
import _ from 'lodash'

const Search = () => {
  const translations = useSelector((state) => state.translations)
  const tables = useSelector((state) => state.tables)
  const token = useSelector((state) => state.token)

  const dispatch = useDispatch()

  const [searchString, setSearchString] = useState('')

  const getSuggestions = async (e) => {
    e?.preventDefault()
    const tablesCopy = _.cloneDeep(tables)
    tablesCopy[0].loading = true

    dispatch(set(tablesCopy))
    const { status, products, count } = await fetch('/product/search', {
      token,
      input: searchString,
    })
    if (status && products) {
      tablesCopy[0] = {
        columns: getSortedColumns(getColumns(products)),
        sorting: {
          key: '',
          direction: 0,
        },
        loading: false,
        compareSelectedProducts: [],
        filter: [],
        hiddenColumns: [],
        searchResult: true,
        items: products,
        count: count,
      }

      dispatch(set(tablesCopy))
    }
  }

  return (
    <div id="search">
      <div className="searchLabel">
        <SearchIcon />
        <div>{translations['search.label']}</div>
      </div>
      <form onSubmit={getSuggestions} className="searchWrapper">
        <input
          className="searchInput"
          value={searchString}
          onChange={(e) => setSearchString(e.target.value)}
        />
        <div className="buttonSearch" onClick={getSuggestions}>
          <SearchIcon />
          <div className="buttonLabel">{translations['search.button']}</div>
        </div>
      </form>
    </div>
  )
}

export default Search
