import { useState } from 'react'
import Input from '../../../UI/Input/Input.js'
import { StyledResult, Wrapper, CustomButton, ButtonWrapper, Warning } from './Result.Styled.js'
// import Preview from './Preview/Preview.js'
import Button from '../../../UI/Button/Button.js'
import fetch from '../../../../fetch.js'
import { useSelector } from 'react-redux'
import Dropdown from '../../../UI/Dropdown/Dropdown.js'

const Result = ({ selectedKey, selectedValue, values }) => {
  // const [keyDefinition, setKeyDefinition] = useState('')
  // const [valueDefinition, setValueDefinition] = useState('')
  const token = useSelector((state) => state.token)
  const [definitions, setDefinitions] = useState([{ type: 'String' }])

  const save = async () => {
    await fetch('/props-edit/save', {
      token,
      keyString: selectedKey?.key,
      valueString: selectedValue?.value,
      // replaceKey: keyDefinition,
      // replaceValue: valueDefinition,
      definitions,
      // type,
    })
    window.location.replace('/configurator')
  }

  const setKeyDefinition = (i) => (value) => {
    setDefinitions(
      definitions.map((def, index) => (index === i ? { ...def, replaceKey: value } : def)),
    )
  }

  const setValueDefinition = (i) => (value) => {
    setDefinitions(
      definitions.map((def, index) => (index === i ? { ...def, replaceValue: value } : def)),
    )
  }

  const setType = (i) => (value) => {
    setDefinitions(definitions.map((def, index) => (index === i ? { ...def, type: value } : def)))
  }

  const getOptions = (i) => {
    const options = [{ key: 'String', value: 'Text' }]
    let canBeParsed = false

    canBeParsed = !isNaN(definitions[i]?.replaceValue)
    if (!canBeParsed) canBeParsed = !isNaN(definitions[i]?.replaceValue?.replace(',', '.'))
    if (canBeParsed) {
      options.push({ key: 'Number', value: 'Zahl' })
      if (definitions[i] && definitions[i].type === 'String' && definitions[i].wasNumber)
        setDefinitions(
          definitions.map((def, index) => (index === i ? { ...def, wasNumber: false } : def)),
        )
    } else {
      if (definitions[i] && definitions[i].type === 'Number')
        setDefinitions(
          definitions.map((def, index) =>
            index === i ? { ...def, type: 'String', wasNumber: true } : def,
          ),
        )
    }

    return options
  }

  return (
    <StyledResult>
      {definitions.map((definiton, i) => (
        <div key={i}>
          <Wrapper>
            {selectedKey && (
              <Input
                value={definiton.replaceKey || ''}
                onChange={setKeyDefinition(i)}
                placeholder="Key Definition"
              />
            )}
            {(selectedValue || values?.length === 0) && (
              <>
                <Input
                  value={definiton.replaceValue || ''}
                  onChange={setValueDefinition(i)}
                  placeholder="Value Definition"
                />
                <Dropdown
                  value={definiton.type || 'String'}
                  onChange={setType(i)}
                  name="Datentyp"
                  options={getOptions(i)}
                />
              </>
            )}
          </Wrapper>
          {definitions[i].wasNumber && (
            <Warning>
              Wert kann nicht als Zahl gespeichert werden! Typ wurde auf "Text" geändert!
            </Warning>
          )}
        </div>
      ))}
      {selectedValue && (
        <ButtonWrapper>
          <CustomButton>
            <Button onClick={() => setDefinitions([...definitions, { type: 'String' }])} text="+" />
          </CustomButton>
        </ButtonWrapper>
      )}
      <Button onClick={save} text="Speichern" />
    </StyledResult>
  )
}

export default Result
