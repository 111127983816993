import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import fetch from '../../../../fetch'
import Checkbox from '../../../UI/Checkbox/Checkbox'
import Item from './Item/Item'
import './List.scss'
import { updateProductList } from '../../../../action/productLists'
import Filter from './Filter/Filter'

const forbiddenColumns = [
  'uuid',
  '_id',
  '__v',
  'props',
  'productComparisonResult',
  'propsComparisonResult',
  'isLatest',
  'providerUUID',
  'description',
]

const List = ({ productList, listIndex, hideButtons = false, readOnly = false }) => {
  const [index, setIndex] = useState(0)
  const [limit, setLimit] = useState(50)

  const isFirst = listIndex === 0

  const [columns, setColumns] = useState([])
  const [excludedColumns, setExcludedColumns] = useState([])
  const [loadMore, setLoadMore] = useState(false)
  const [isLastPage, setIsLastPage] = useState(false)

  const [allProps, setAllProps] = useState(null)

  const [translations, setTranslations] = useState(null)

  const [providerList, setProviderList] = useState(null)

  const selectedProduct = useSelector((state) => state.selectedProductForComparison)

  const token = useSelector((state) => state.token)

  const dispatch = useDispatch()

  useEffect(() => {
    const initColumns = () => {
      const cols = productList.items.reduce((acc, prod) => {
        Object.keys(prod).forEach((key) => {
          if (!acc.includes(key) && !forbiddenColumns.includes(key)) {
            if ((Array.isArray(prod[key]) && prod[key].length > 0) || !Array.isArray(prod[key]))
              acc.push(key)
          }
        })
        return acc
      }, columns)
      setColumns(cols)
    }

    const getProducts = async () => {
      const { status, products: newProducts } = await fetch('/product/get', {
        index,
        limit,
        producer: productList.producer,
        token,
      })
      if (status && newProducts) {
        if (!productList.items) {
          dispatch(updateProductList({ ...productList, items: newProducts }, listIndex))
        } else
          dispatch(
            updateProductList(
              { ...productList, items: [...productList.items, ...newProducts] },
              listIndex,
            ),
          )

        if (newProducts.length < limit) setIsLastPage(true)
        setLoadMore(false)
        setAllProps(null)
        setColumns([])
      }
    }

    const getAllProps = async () => {
      const { status, props } = await fetch('/props/get', {
        producer: productList.producer,
        getBoth: true,
        token,
      })
      if (status && props) {
        setAllProps(props)
      }
    }

    const getTranslations = async () => {
      const { status, translations } = await fetch('/propTranslations/get', {
        producer: productList.producer,
        getBoth: true,
        token,
      })
      if (status && translations) {
        setTranslations(translations)
      } else {
        setTranslations([])
      }
    }

    const getProvider = async () => {
      const { status, result } = await fetch('/provider/get', { token })
      if (status && result) setProviderList(result)
    }

    if (!providerList) getProvider()

    if (productList.items && columns.length === 0) initColumns()
    if ((!productList.items || loadMore) && productList.producer) getProducts()
    if (!allProps && productList.producer) getAllProps()
    if (!translations && productList.producer) getTranslations()
  }, [
    productList,
    index,
    limit,
    columns,
    excludedColumns,
    loadMore,
    allProps,
    translations,
    isFirst,
    dispatch,
    listIndex,
    token,
    providerList,
  ])

  const onClickCheckbox = (col) => (e) => {
    setExcludedColumns(e ? excludedColumns.filter((c) => c !== col) : [...excludedColumns, col])
  }

  const onClickLoadMore = () => {
    setIndex(index + 1)
    setLoadMore(true)
  }

  const onChangeSelect = (e) => {
    setLimit(Number.parseInt(e.target.selectedOptions[0].innerText))
    dispatch(updateProductList({ ...productList, items: null }, listIndex))
  }

  const onChangeProducer = (e) => {
    const provider = providerList.find((p) => p.uuid === e.target.selectedOptions[0].value)
    dispatch(updateProductList({ ...productList, producer: provider, items: null }, listIndex))
  }

  if (!productList && !columns) return <></>

  return (
    <div id="list">
      {!readOnly && (
        <div className="producer">
          <select
            onChange={onChangeProducer}
            value={(productList.producer && productList.producer.uuid) || ''}
          >
            <option>---bitte Wählen---</option>
            {providerList &&
              Array.isArray(providerList) &&
              providerList.map((prov) => (
                <option key={prov.uuid} value={prov.uuid}>
                  {prov.name}
                </option>
              ))}
          </select>
        </div>
      )}

      {productList.producer && (
        <>
          {!readOnly && (
            <div>
              <select onChange={onChangeSelect}>
                <option>50</option>
                <option>10</option>
                <option>20</option>
                <option>100</option>
                <option>200</option>
                <option>500</option>
                <option>1000</option>
              </select>
            </div>
          )}

          <div className="excludedCols">
            {columns &&
              Array.isArray(columns) &&
              columns.map((col) => (
                <div key={col}>
                  <Checkbox
                    checked={!excludedColumns.includes(col)}
                    onChange={onClickCheckbox(col)}
                    text={col}
                  />
                </div>
              ))}
          </div>

          {!readOnly && (
            <div className="filterSection">
              <Filter
                productList={productList}
                allProps={allProps}
                translations={translations}
                listIndex={listIndex}
              />
            </div>
          )}

          <div className="columns">
            {columns &&
              Array.isArray(columns) &&
              columns
                .filter((c) => !excludedColumns.includes(c))
                .map((col) => {
                  return (
                    <div key={col}>
                      {translations &&
                      Array.isArray(translations) &&
                      translations.find((t) => t.key === col)
                        ? translations.find((t) => t.key === col).translation
                        : col}
                    </div>
                  )
                })}
          </div>

          {(!isFirst || (isFirst && !selectedProduct)) && (
            <div className="products">
              {productList.items &&
                Array.isArray(productList.items) &&
                productList.items.map((prod) => {
                  return (
                    <div key={prod.uuid}>
                      <Item
                        isFirst={isFirst}
                        product={prod}
                        columns={columns}
                        excludedColumns={excludedColumns}
                        translations={translations}
                        producer={productList.producer}
                        listIndex={listIndex}
                        hideButtons={hideButtons}
                      />
                    </div>
                  )
                })}
            </div>
          )}

          {isFirst && selectedProduct && (
            <div className="products">
              <Item
                isFirst={isFirst}
                product={selectedProduct}
                columns={columns}
                excludedColumns={excludedColumns}
                translations={translations}
                producer={productList.producer}
                listIndex={listIndex}
                hideButtons={hideButtons}
              />
            </div>
          )}
          <div className="btnShowMoreWrapper">
            {!isLastPage && productList.items && productList.items.length >= limit && (
              <div className="btnShowMore" onClick={onClickLoadMore}>
                load more
              </div>
            )}
          </div>
        </>
      )}
    </div>
  )
}

export default List
