import { useEffect, useState } from 'react'
import Cookies from 'universal-cookie'
import { setUserToken, setUser } from '../../../action/user'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import fetch from '../../../fetch'

const Secure = (props) => {
  const authRequired = props.authRequired || false
  const user = useSelector((state) => state.user)
  const dispatch = useDispatch()
  const history = useHistory()
  const [isEvaluated, setIsEvaluated] = useState(false)

  useEffect(() => {
    const cookies = new Cookies()
    const token = cookies.get('token', { path: '/' })

    const evaluate = (user, newToken) => {
      dispatch(setUser(user))
      dispatch(setUserToken(newToken || token))
      setIsEvaluated(true)
    }

    const invalidatedUser = (withRedirect = false) => {
      cookies.remove('token', { path: '/' })
      dispatch(setUser(null))
      dispatch(setUserToken(null))
      if (withRedirect) {
        setIsEvaluated(false)
        history.push('/login')
      } else {
        setIsEvaluated(true)
      }
    }

    const resolveToken = async () => {
      const { status, user, token: newToken } = await fetch('/auth/resolve-token', { token })
      if (status && user) {
        if (authRequired === 'admin') {
          if (user.permissions === 'admin') {
            evaluate(user, newToken)
          } else {
            invalidatedUser(true)
          }
        } else {
          evaluate(user, newToken)
        }
      } else {
        invalidatedUser(!!authRequired)
      }
    }

    if (!isEvaluated) {
      if (!user && token) {
        resolveToken()
      } else if (authRequired && !user) {
        invalidatedUser(true)
      } else {
        setIsEvaluated(true)
      }
    }
  }, [isEvaluated, authRequired, dispatch, history, user])

  return isEvaluated ? <>{props.children}</> : <></>
}

export default Secure
