import styled from 'styled-components'

export const StyledLib = styled.div`
  margin-top: 50px;
  border-bottom: 1px solid black;
  padding-bottom: 20px;
  margin-left: ${({ ancestorCount }) => ancestorCount * 20}px;
`

export const Name = styled.div`
  font-size: 20px;
`

export const Result = styled.div`
  display: flex;
  gap: 20px;
  width: 100%;
`
export const Key = styled.div`
  width: 50%;
`
export const Value = styled.div`
  width: 50%;
`
