import { useState, useEffect } from 'react'
import { getUUID } from '../../../../../utility'
import FilterItem from './FilterItem/FilterItem'
import { useDispatch, useSelector } from 'react-redux'
import { updateProductList } from '../../../../../action/productLists'
import fetch from '../../../../../fetch'

const Filter = ({ productList, allProps, translations, listIndex }) => {
  const [filter, setFilter] = useState(null)
  const [selectedProp, setSelectedProp] = useState(null)
  const [searchString, setSearchString] = useState('')
  const reduxFilter = productList.filter
  const dispatch = useDispatch()

  const token = useSelector((state) => state.token)

  useEffect(() => {
    if (reduxFilter && Array.isArray(reduxFilter) && reduxFilter.length > 0 && !filter) {
      setFilter([...reduxFilter])
    }
  }, [reduxFilter, filter])

  const onClickFilter = async () => {
    const { status, results } = await fetch('/product/filter', {
      filter,
      producer: productList.producer,
      token,
    })
    if (status && results) {
      dispatch(updateProductList({ ...productList, items: results, filter: filter }, listIndex))
    }
  }

  const onClickAddFilter = () => {
    setFilter([...(filter || []), { selectedProp, searchString }])
    setSelectedProp(null)
    setSearchString('')
  }

  const onClickRemove = (f) => {
    setFilter(filter.filter((fi) => fi !== f))
  }

  return (
    <div>
      {filter &&
        Array.isArray(filter) &&
        filter.map((filter, i) => (
          <div key={getUUID()}>
            <FilterItem filter={filter} remove={() => onClickRemove(filter)} />
          </div>
        ))}

      <div>
        <select
          onChange={(e) =>
            setSelectedProp(allProps.find((p) => p.key === e.target.selectedOptions[0].value))
          }
          value={(selectedProp && selectedProp.key) || ''}
        >
          <option>zum Filtern Auswählen</option>
          {allProps &&
            Array.isArray(allProps) &&
            allProps.map((prop) => (
              <option value={prop.key} key={prop.key}>
                {translations &&
                Array.isArray(translations) &&
                translations.find((t) => t.key === prop.key)
                  ? translations.find((t) => t.key === prop.key).translation
                  : prop.key}
              </option>
            ))}
        </select>

        <div>
          <div>Suche</div>
          <input
            type="text"
            value={searchString}
            onChange={(e) => setSearchString(e.target.value)}
          />
        </div>
        <div onClick={onClickAddFilter}>Filter hinzufügen</div>
      </div>

      <div className="btnFilter" onClick={onClickFilter}>
        Filtern
      </div>
    </div>
  )
}

export default Filter
