import { v4 as uuidv4 } from "uuid";
import profileImagePlaceholder from "./assets/image/profile-placeholder.png";
import companyImagePlaceholder from "./assets/image/logo-placeholder.png";
const FileDownload = require("js-file-download");

const currencyFormatter = require("currency-formatter");
const prettyBytes = require("pretty-bytes");
export const projectName = "bayka-c";

export const triggerClientSideDownload = (data, filename) =>
  FileDownload(data, filename);

const crawlerDomains = {
  Nexans: "https://nexans.de",
  Helukabel: "https://shop.helukabel.com",
};

export const backendDomain =
  process.env.NODE_ENV === "production"
    ? "https://backend-lib.bayka.de"
    : "http://localhost:8100";
export const backendSocketIODomain =
  process.env.NODE_ENV === "production"
    ? "https://backend-lib.bayka.de"
    : "http://localhost:8100";

export const getUUID = () => uuidv4();

export const buildURL = (producer, urlSuffix) =>
  crawlerDomains[producer] + urlSuffix;

export const getQueryString = (string, requiredString) => {
  if (
    typeof string === "string" &&
    string.length > 0 &&
    typeof requiredString === "string"
  ) {
    string = string.substring(1);
    const split = string.split("=");
    return !string.includes("&") &&
      split.length === 2 &&
      split[0] === requiredString &&
      split[1].length > 0
      ? decodeURIComponent(split[1])
      : false;
  } else {
    return false;
  }
};

export const escapeRegex = (string) =>
  string.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");

export const presignedUploadURLEndpoint = "/upload/get-presigned-url";

export const frontendDomain =
  process.env.NODE_ENV === "production"
    ? "https://lib.bayka.de"
    : "http://localhost:3000";

export const fallbackProfilImage = profileImagePlaceholder;
export const fallbackCompanyImage = companyImagePlaceholder;

export const getPrettyTime = (date) => {
  // date.setMinutes(date.getMinutes() + (date.getTimezoneOffset() * -1))
  const fill = (number) => {
    number = `${number}`;
    return number.length === 1 ? `0${number}` : number;
  };
  const minute = fill(date.getMinutes());
  const hour = fill(date.getHours());
  return `${hour}:${minute}`;
};
export const getPrettyDateTime = (date) => {
  // date.setMinutes(date.getMinutes() + (date.getTimezoneOffset() * -1))
  const fill = (number) => {
    number = `${number}`;
    return number.length === 1 ? `0${number}` : number;
  };
  const day = fill(date.getDate());
  const month = fill(date.getMonth() + 1);
  const year = date.getFullYear();
  const minute = fill(date.getMinutes());
  const hour = fill(date.getHours());
  return `${day}.${month}.${year} ${hour}:${minute}`;
};
export const getPrettyDate = (date) => {
  const fill = (number) => {
    number = `${number}`;
    return number.length === 1 ? `0${number}` : number;
  };
  const day = fill(date.getDate());
  const month = fill(date.getMonth() + 1);
  const year = date.getFullYear();
  return `${day}.${month}.${year}`;
};

export const addMissingZero = (input) => {
  if (`${input}`.length === 1) {
    return `0${input}`;
  }
  return input;
};

export const formatFileSize = (sizeInBytes, locale = "de") => {
  return prettyBytes(sizeInBytes, { locale });
};

export const formatPrice = (priceInCents, locale = "de-DE") => {
  return currencyFormatter.format(priceInCents / 100, { locale });
};
