import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { setTranslations, setLang, setLangOptions } from '../../action/translations'
import fetch from '../../fetch'


const Translations = (props) => {
    const translations = useSelector(state => state.translations)
    const token = useSelector(state => state.token)
    const lang = useSelector(state => state.lang)
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        const getTranslations = async () => {
            const { status, translations, langOptions, lang: newLang } = await fetch('/translations/get', { token, lang })
            if (status && translations && newLang && langOptions) {
                dispatch(setTranslations(translations))
                dispatch(setLangOptions(langOptions))
                dispatch(setLang(newLang))
                setLoading(false)
            }
        }

        if (!translations) {
            getTranslations()
        } else {
            setLoading(false)
        }
    }, [dispatch, token, lang, translations])

    if (loading) return <></>
    return <>{props.children}</>
}

export default Translations