import { useSelector } from 'react-redux'

import VisibilityChecker from '../../../UI/VisibilityChecker/VisibilityChecker'
import TableRow from '../TableRow/TableRow'

const TableBody = ({
  tableIndex,
  isLastPage,
  setPageIndex,
  pageIndex,
  columnsToDisplay,
  fullscreen,
  table,
}) => {
  const similarSelectedProduct = useSelector((state) => state.similarSelectedProduct)

  const { sorting, items: data, filter } = table

  const sortData = () => {
    if (sorting.direction !== 0) {
      const sortedData = data.sort((a, b) => {
        if (sorting.direction === 1) {
          if (a[sorting.key] < b[sorting.key]) return 1
          else return -1
        } else if (sorting.direction === -1) {
          if (a[sorting.key] < b[sorting.key]) return -1
          else return 1
        } else return 0
      })
      return sortedData
    } else {
      return data
    }
  }

  const onChangeVisibility = (isVisible) => {
    if (isVisible && !isLastPage) setPageIndex(pageIndex + 1)
  }

  return (
    <div className={`tableBody ${fullscreen ? 'fullscreen' : ''}`} id={`tableBody-${tableIndex}`}>
      {sortData().map((item, i) => (
        <TableRow
          key={i}
          tableIndex={tableIndex}
          item={item}
          i={i}
          columnsToDisplay={columnsToDisplay}
        />
      ))}
      {!similarSelectedProduct && filter.length === 0 && !table.searchResult && (
        <div className="tableRow">
          {data.length > 0 && !similarSelectedProduct && (
            <VisibilityChecker onChange={onChangeVisibility}>
              <div className="loadingPlaceholder">loading</div>
            </VisibilityChecker>
          )}
        </div>
      )}
    </div>
  )
}

export default TableBody
