import Modal from '../../UI/Modal/Modal'
import './Tutorial.scss'
// import ScreenshotTutorial from '../../../assets/image/Screenshot Tutorial.png'

const Tutorial = ({ showModal, handleClose }) => {
  return (
    <Modal open={showModal} close={handleClose} title="Anleitung" size="xl">
      <div className="tutorialContent">
        {/* <div className="paragraph">
          <div className="title">Regex</div>
          <div className="paragraph">
            Reguläre ausdrücke definieren in diesem Anwendungsfall ein Muster, welches aufgrund
            definierter Regeln eine beliebige Zeichenkette in die gewünschte Form bringt.
          </div>

          <div className="title">Ziel</div>
          <div className="paragraph">
            Ziel dieses Tools ist es, Daten in eine einheitliche Form zu bringen. Außerdem kann man
            verschiedene Eigenschaften durch neuen Text ersetzen (z. B. Umbenennen).
          </div>

          <div className="title">Beispiel</div>
          <div className="subTitle">Gegebener Datensatz:</div>
          <div className="data paragraph">
            <div className="paragraph">
              <div className="subTitle">key (Eigenschaftsbezeichnung/ Spaltenname)</div>
              <div className="paragraph">title</div>
            </div>
            <div className="paragraph">
              <div className="subTitle">value (Wert/ Wert in einer Zeile)</div>
              <div className="paragraph">JZ-500 grau 3 G 1,5 mm²</div>
            </div>
          </div>
          <div className="paragraph">
            Die eben definierten Daten sind in einer Form, wie sie aus dem Crawlingdurchlauf kommen
            könnten.
          </div>
          <div className="paragraph">
            Für den Mensch verständlicher wäre die Spalte "Artikelname" anstatt "title".
          </div>
          <div className="paragraph">
            Dies wird durch einen regulären Ausdruck, der nur auf die Eigenschaft(key) "title" hört.
          </div>
          <div className="paragraph">
            Das erste Eingabefeld lässt dich einen beliebigen Text eingeben um deine Ausdrücke zu
            testen. (Es würde sinn machen den Text einzufügen den man separieren will) Das 2.
            Eingabefeld ist für den Ausdruck vorgesehen. Das Dropdown Menü enthält die Ergebnisse
            des eingegebenen Ausdrucks.
          </div>
          <div className="title">Ausdruck</div>
          <div className="cursive">"^(title)$"</div>
          <div className="paragraph">
            Dieser Ausdruck definiert eine Gruppe, die als Ergebnis bei treffender Zeichenkette
            zurückgegeben wird.
          </div>
          <div className="paragraph">
            Das Haus "^" sagt aus, dass der folgende Ausdruck am anfang stehen muss und das "$" am
            Ende. Also kein platz für weitere Zeichen wie z. B. "titlee".
          </div>
          <div className="paragraph">
            Runde klammern definieren die Gruppe und der Inhalt die Bedingungen. In diesem Fall wird
            nur eine Gruppe zurückgegeben wenn der key(Eigenschaft) "title" ist.
          </div>
          <div className="paragraph">
            In dem Konfigurator stehen unten 2 Eingabefelder jeweils zur Key und Value definition
            des Ergebnisses.
          </div>
          <div className="paragraph">
            durch unsere Expression kommt eine Gruppe "title" zurück die wir im Value definition
            Eingabefeld durch einen beliebigen Wert überschreiben können.
          </div>
          <div className="paragraph">Key definition: "{'{{key.0.0.0}}'}"</div>
          <div className="paragraph">Value definition: "Artikelname"</div>
          <div className="paragraph">Fertig ist der erste reguläre Ausdruck</div>
          <div className="paragraph">
            Die geschweiften Klammern fungieren als Platzhalter für die Variabel "key.0.0.0".
          </div>
          <div className="paragraph">
            Die 3 stellen stehen für die Selektion des Regex Ergebnisses. Die erste Stelle definiert
            die Ebene angefangen bei 0. Falls es bei der Ebene mehrere parallele Regex Ausdrücke
            gibt wird das in der 2. Stelle angegeben. Die 3. Stelle weist auf die Gruppe aus diesem
            Regex Ergebnis hin.
          </div>
          <div className="paragraph">
            key.0.0.0 wäre Die erste Ebene, davon der erste Eintrag und davon das erste Ergebnis.
            Dies wird aber auch in den einzelnen Ebenen angezeigt bis zur 2. Stelle, die 3. Bezieht
            sich dann auf die Gruppen in diesem Element.
          </div>
          <div className="imageWrapper">
            <img src={ScreenshotTutorial} alt="screenshot" />
          </div>
          <div className="paragraph"></div>
          <div className="title">Das Ergebnis:</div>
          <div className="data paragraph">
            <div className="paragraph">
              <div className="subTitle">key (Eigenschaftsbezeichnung/ Spaltenname)</div>
              <div className="paragraph">Artikelname</div>
            </div>
            <div className="subTitle">
              <div className="subTitle">value (Wert/ Wert in einer Zeile)</div>
              <div className="paragraph">JZ-500 grau 3 G 1,5 mm²</div>
            </div>
          </div>
          <div className="title">Platzhalter</div>
          <div className="paragraph">
            Ein Platzhalter lässt Variabilität in die Ausdrücke bringen. Ein Ausdruck muss nicht nur
            auf eine Zeichenkette passen wie im Beispiel oben Beschrieben.
          </div>
          <div className="paragraph">
            Gegebene Zeichenkette (egal ob key oder value): "Aderisolation: PVC, Mischungstyp Z
            7225"
          </div>
          <div className="title">Ziel</div>
          <div className="paragraph">
            Ziel ist es die Eigenschaftsbezeichnung von dem Wert zu trennen.
          </div>
          <div className="paragraph">
            Es werden dazu 2 Gruppen "()" benötigt. Da diese Regel aber auch auf andere
            Zeichenketten zutreffen soll, ist das Ziel, den Ausdruck allgemein zu halten. Anstatt
            "(Aderisolation)" für die erste Gruppe wollen wir beliebige Zeichen haben: "(.*)" Um
            eine 2. Gruppe hinzuzufügen, die bei dem Zeichen ":" trennt gehen wir wie folgt vor.
            "^(Aderisolation):(.*)$". Dieser Ausdruck separiert immer bei dem Doppelpunkt, egal was
            davor oder danach kommt.
          </div>
          <div className="paragraph">
            Das Ergebnis sieht wie folgt aus: Gruppe 1: "Aderisolation" Gruppe 2: "PVC, Mischungstyp
            Z 7225"
          </div>
          <div className="paragraph">
            Mit diesen Gruppen kann man dann eine key/ value definition erstellen.
          </div>
          <div className="paragraph">
            Key definition: {'{{key.0.0.0}}'} (Wenn die ursprüngliche Zeichenkette als Key
            gespeichert war ansonsten value)
          </div>
          <div className="paragraph">Value definition: {'{{key.0.0.1}}'}</div>
          <div className="title">Als Ergebnis erhält man</div>
          <div className="data paragraph">
            <div className="paragraph">
              <div className="subTitle">key (Eigenschaftsbezeichnung/ Spaltenname)</div>
              <div className="paragraph">Aderisolation</div>
            </div>
            <div className="subTitle">
              <div className="subTitle">value (Wert/ Wert in einer Zeile)</div>
              <div className="paragraph">PVC, Mischungstyp Z 7225</div>
            </div>
          </div>
          <div className="paragraph">
            Falls eine Zeichenkette mehrere Werte beinhaltet, kann man diese auch auf mehrere
            Datensätze verteilen.
          </div>
          <div className="paragraph">
            Wie das beispiel von oben ändert sich bei dem Key Ausdruck nichts. Nur bei dem Value
            Austruck wird noch eine Ebene hinzugefügt. (Gruppe 2 anklicken und "Filter hinzufügen")
          </div>
          <div className="paragraph">
            Der 2. Filter hat als Ausgangswert den Inhalt von Gruppe 2 des ersten Ausdruck
            Ergebnisses. ("PVC, Mischungstyp Z 7225") Dieser soll nach dem Komma getrennt werden um
            daraus 2 Einträge zu machen
          </div>
          <div className="paragraph">Ausdruck: (.*),(.*)</div>
          <div className="paragraph">Als Value definition wird dann {'{{[key.1.0]}}'}</div>
          <div className="paragraph">
            Die Eckigen klammern definieren ein „Array“, also mehrere Werte. Dort werden dann alle
            Gruppen dieses Eintrags verwendet, deswegen ohne 3. Stelle.
          </div>
        </div> */}
        <div className="paragraph">
          <div className="title">Konfigurator</div>
          <div className="paragraph">
            Er gibt die Möglichkeit, aufgrund ausgewählter Eigenschaften und Werte, diese zu
            bearbeiten.
          </div>

          <div className="title">Suche</div>
          <div className="paragraph">
            Es kann nach Eigenschaften gesucht werden. (ohne Duplikate)
          </div>

          <div className="title">Auswahl</div>
          <div className="paragraph">
            Nachdem eine Eigenschaft aus der Liste ausgewählt wurde, erscheinen (falls vorhanden)
            die dazugehörigen Werte. (ohne Duplikate)
          </div>
          <div className="title">Eigenschaft umbenennen</div>
          <div className="paragraph">
            Wenn nur eine Eigenschaft ausgewählt wurde, kann auch nur diese Eigenschaft umbenannt
            werden. Alle möglichen Werte, werden unter dem neuen Namen der Eigeschaft gespeichert.
          </div>
          <div className="title">Eigenschaft mit einem bestimmten Wert umbenennen</div>
          <div className="paragraph">
            Wenn eine Eigenschaft und ein Wert ausgewählt wurde, werden die Umbenennungen der
            Eigenschaft und/oder des Wertes, nur das Eigenschaft-Wert-Paar betreffen.
          </div>
          <div className="title">Alle Werte einer Eigenschaft umbenennen</div>
          <div className="paragraph">
            Falls eine Eigenschaft ausgewählt wurde, welche keine Werte hat, kann ein Wert für alle
            Einträge manuell eingetragen werden.
          </div>
          <div className="title">Allgemein gilt</div>
          <div className="paragraph">
            Wenn eine Eigenschaft oder ein Wert nicht umbenannt wird (Eingabefeld leer), wird der
            ursprüngliche Name beibehalten.
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default Tutorial
