const forbiddenColumns = ['uuid', 'description', 'isLatest', 'providerUUID']
export const firstColumnsDefault = ['title', 'key']

export const getColumns = (products) => {
  if (Array.isArray(products)) {
    const columns = new Set()
    for (const item of products) {
      Object.keys(item).forEach((key) => {
        if (!forbiddenColumns.includes(key)) columns.add(key)
      })
    }
    return Array.from(columns)
  }
}

export const getSortedColumns = (columns) => {
  for (let i = 0; i < firstColumnsDefault.length; i++) {
    const indexOfProp = columns.indexOf(firstColumnsDefault[i])
    columns[indexOfProp] = columns[i]
    columns[i] = firstColumnsDefault[i]
  }
  return columns
}
