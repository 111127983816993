import { Switch } from 'react-router-dom'
// import Settings from './components/Dashboard/Settings/Settings'
// import NewObjects from './components/Dashboard/NewObjects/NewObjects'
// import MyObjects from './components/Dashboard/MyObjects/MyObjects'
// import NewObjectPipeline from './components/Dashboard/NewObjects/Pipeline/Pipeline'
// import Chat from './components/Dashboard/Chat/Chat'
// import ObjectDetail from './components/Dashboard/ObjectDetail/ObjectDetail'

function DashboardRouting({ match }) {
  return (
    <Switch>
      {/* SETTINGS
      <Route exact={true} path={`${match.path}/settings`} component={Settings} />
      {/* NEW OBJECTS */}
      {/* <Route exact={true} path={`${match.path}/new-objects`} component={NewObjects} />
      {/* NEW OBJECT CREATE */}
      {/* <Route exact={true} path={`${match.path}/new-object-create`} component={NewObjectPipeline} /> */}
      {/* MY OBJECTS */}
      {/* <Route exact={true} path={`${match.path}/my-objects`} component={MyObjects} /> */}
      {/* OBJECT DETAIL */}
      {/* <Route exact={true} path={`${match.path}/object-detail/:uuid`} component={ObjectDetail} /> */}
      {/* CHAT */}
      {/* <Route exact={true} path={`${match.path}/chat`} component={Chat} /> */}
    </Switch>
  )
}

export default DashboardRouting
