import fetch from '../../../../fetch'

export const getProducts = async (limit, index, token) => {
  const {
    status,
    products: newProducts,
    count,
  } = await fetch('/product/get', {
    index,
    limit,
    token,
  })
  if (status && newProducts) {
    return { products: newProducts, count }
  } else return false
}

export const getProps = async (token) => {
  const { status, props } = await fetch('/props/get', { token })
  if (status && props) {
    return props
  } else return false
}

export const getFilter = async (token, filter) => {
  const { status, results } = await fetch('/product/filter', { token, filter })
  if (status && results) {
    return results
  } else return false
}
