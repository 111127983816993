import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import fetch from '../../../fetch'
import Layout from '../../Layout/Layout'
import UserCreateModal from './UserCreateModal/UserCreateModal'
import UserEditModal from './UserEditModal/UserEditModal'
import './Users.scss'
import Button from '../../UI/Button/Button'

const Users = () => {
  const [users, setUsers] = useState(null)
  const token = useSelector((state) => state.token)
  const [showEditModal, setShowEditModal] = useState(false)
  const [showCreateModal, setShowCreateModal] = useState(false)
  const [selectedUserUUID, setSelectedUserUUID] = useState(null)

  useEffect(() => {
    const getUsers = async () => {
      const { status, users } = await fetch('/admin/users/get', { token })
      if (status && users) setUsers(users)
    }
    if (!users && token) getUsers()
  }, [token, users])

  const onClickUsername = (user) => () => {
    setSelectedUserUUID(user.uuid)
    setShowEditModal(true)
  }

  const handleEditClose = () => {
    setShowEditModal(false)
    setSelectedUserUUID(null)
  }

  const handleCreateClose = () => {
    setShowCreateModal(false)
  }

  const onClickCreate = () => {
    setShowCreateModal(true)
  }

  const onClickRemove = (userUUID) => async () => {
    const { status } = await fetch('/admin/user/delete', { token, userUUID })
    if (status) setUsers(null)
  }

  return (
    <Layout search={false} authRequired={true}>
      <div id="admin-users">
        <Button className="createUserBtn" text="Neuer Benutzer anlegen" onClick={onClickCreate} />

        <div>
          {users &&
            Array.isArray(users) &&
            users.map((user) => (
              <div key={user.uuid} className="user">
                <div onClick={onClickUsername(user)} className="names">
                  <div>{user.firstname}</div>
                  <div>{user.lastname}</div>
                </div>
                <div className="btnDeleteWrapper">
                  <Button fullWidth={false} text="löschen" onClick={onClickRemove(user.uuid)} />
                </div>
              </div>
            ))}
        </div>
        {showEditModal && selectedUserUUID && (
          <UserEditModal
            showModal={showEditModal}
            handleClose={handleEditClose}
            userUUID={selectedUserUUID}
          />
        )}
        {showCreateModal && (
          <UserCreateModal showModal={showCreateModal} handleClose={handleCreateClose} />
        )}
      </div>
    </Layout>
  )
}

export default Users
