import { useState } from 'react'
import Modal from '../../../UI/Modal/Modal'
import { useSelector } from 'react-redux'
import fetch from '../../../../fetch'
import Input from '../../../UI/Input/Input'
import Button from '../../../UI/Button/Button'

const UserCreateModal = ({ showModal, handleClose }) => {
  const token = useSelector((state) => state.token)
  const [user, setUser] = useState({
    firstname: '',
    lastname: '',
    mail: '',
    password: '',
    permissions: 'read',
  })

  const onChangeValue = (key) => (value) => {
    const copy = { ...user }
    copy[key] = value
    setUser(copy)
  }

  const onClickSave = async () => {
    const { status } = await fetch('/admin/user/create', { token, user })
    if (status) {
      handleClose()
    }
  }

  const onChangePermission = (e) => {
    const copy = { ...user }
    copy.permissions = e.target.selectedOptions[0].value
    setUser(copy)
  }

  return (
    <Modal open={showModal} close={handleClose} title="Benutzer bearbeiten">
      <div className="modalContent">
        {user &&
          Object.keys(user).map((key) => (
            <div key={key} className="space">
              {key !== 'permissions' && key !== 'password' && (
                <Input
                  type="text"
                  value={user[key]}
                  placeholder={key}
                  onChange={onChangeValue(key)}
                />
              )}
              {key === 'permissions' && (
                <select value={user[key]} onChange={onChangePermission}>
                  <option value="read">Lesen</option>
                  <option value="admin">Admin</option>
                </select>
              )}
              {key === 'password' && (
                <Input
                  type="password"
                  placeholder={key}
                  value={user[key]}
                  onChange={onChangeValue(key)}
                />
              )}
            </div>
          ))}
        <div className="space">
          <Button onClick={onClickSave} text="Speichern" />
        </div>
      </div>
    </Modal>
  )
}

export default UserCreateModal
