import React, { Component } from 'react'
import Container from 'react-bootstrap/Container'
import './Imprint.scss'

class Imprint extends Component {
  render() {
    return (
      <div id="imprint" className="default-padding">
        <Container>
          <div className="headline">Impressum</div>
          <div className="subheadline">COMPANYXXXXXXX</div>
        </Container>
      </div>
    )
  }
}

export default Imprint
