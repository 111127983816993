import TableOptionsIcon from '../../../../assets/image/Icons/Icon-Tabellenspalten.png'

import TableFullscreenIcon from '../../../../assets/image/Icons/Icon-Tabelle-vergroesern.png'
import TableFilterIcon from '../../../../assets/image/Icons/Icon-Filter-blau.png'

import TableOptions from '../TableOptions/TableOptions'
import TableFilter from '../TableFilter/TableFilter'
import { useState } from 'react'

const TableOptionsRow = ({ tableIndex, setFullscreen, fullscreen }) => {
  const [showTableFilter, setShowTableFilter] = useState(false)
  const [showTableOptions, setShowTableOptions] = useState(false)

  const openFullscreen = () => {
    const elem = document.getElementsByClassName('productTableWrapper')[0]
    if (elem.requestFullscreen) {
      elem.requestFullscreen()
    } else if (elem.webkitRequestFullscreen) {
      /* Safari */
      elem.webkitRequestFullscreen()
    } else if (elem.msRequestFullscreen) {
      /* IE11 */
      elem.msRequestFullscreen()
    }
  }

  const handleTableOptionsClose = () => setShowTableOptions(false)

  const handleTableFilterClose = () => setShowTableFilter(false)

  return (
    <div className="optionsRow">
      <div className="icons"></div>
      <div className="tableOptionsWrapper">
        <div className="image-wrapper">
          {!fullscreen && (
            <div className="image" title="Tabelle filtern">
              <img onClick={() => setShowTableFilter(true)} src={TableFilterIcon} alt="filter" />
            </div>
          )}
          <div className="image" title="Tabelle vergrößern">
            <img
              onClick={() => {
                setFullscreen(!fullscreen)
                if (!fullscreen) openFullscreen()
                else document.exitFullscreen()
              }}
              src={TableFullscreenIcon}
              alt="fullscreen"
            />
          </div>
          {!fullscreen && (
            <div className="image" title="Tabellen Optionen">
              <img
                onClick={() => setShowTableOptions(true)}
                src={TableOptionsIcon}
                alt="tableOptions"
              />
            </div>
          )}
        </div>
      </div>
      <TableOptions
        tableIndex={tableIndex}
        showModal={showTableOptions}
        handleClose={handleTableOptionsClose}
      />
      <TableFilter
        showModal={showTableFilter}
        handleClose={handleTableFilterClose}
        tableIndex={tableIndex}
      />
    </div>
  )
}

export default TableOptionsRow
