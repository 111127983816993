import fetch from "../../../../fetch"
import RadioGroup from "../../../UI/RadioGroup/RadioGroup"
import { useState } from "react"
import { useSelector } from "react-redux"
import Button from '../../../UI/Button/Button'
import Input from '../../../UI/Input/Input'

const Create = ({ loadSettings, applicationOptions }) => {

    const translations = useSelector(state => state.translations)
    const token = useSelector(state => state.token)
    const [application, setApplication] = useState('frontend')
    const [group, setGroup] = useState('')
    const [key, setKey] = useState('')
    const [value, setValue] = useState('')
    const [translationKey, setTranslationKey] = useState('')
    const [message, setMessage] = useState(null)

    const create = async () => {
        setMessage(null)
        if (key.length > 0 && group.length > 0 && value.length > 0) {
            const { status } = await fetch('/admin/settings/upsert', { token, application, key, group, value, translationKey })
            if (status) {
                setMessage(translations['admin.settings.create-message.success'])
                setGroup('')
                setKey('')
                setValue('')
                setTranslationKey('')
                loadSettings()
            } else {
                setMessage(translations['admin.settings.create-error.duplicate'])
            }
        } else {
            setMessage(translations['admin.settings.create-error.value-missing'])
        }
    }

    return <div className="create-wrapper">
        <div className="application-selection">
            <div className="selection">
                <RadioGroup
                    name={translations["admin.settings.application"]}
                    value={application}
                    onChange={setApplication}
                    options={applicationOptions.map(ao => ({ key: ao.value, value: ao.label }))}
                />
            </div>
        </div>
        <div className="property">
            <Input value={group} onChange={setGroup} placeholder={translations['admin.settings.group']} />
        </div>
        <div className="property">
            <Input value={key} onChange={setKey} placeholder={translations['admin.settings.key']} />
        </div>
        <div className="property">
            <Input value={value} onChange={setValue} placeholder={translations['admin.settings.value']} />
        </div>
        <div className="property">
            <Input value={translationKey} onChange={setTranslationKey} placeholder={translations['admin.settings.translationkey']} />
        </div>
        <Button text={translations['admin.settings.create']} onClick={create} />
        {message && <div className="error">{message}</div>}
    </div>
}

export default Create