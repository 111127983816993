import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import fetch from '../../../fetch.js'
import { StyledHistory, Headline } from './History.Styled.js'
import Edit from './Edit/Edit.js'

const History = () => {
  const token = useSelector((state) => state.token)
  const [edits, setEdits] = useState(null)

  useEffect(() => {
    const fetchRegexes = async () => {
      const { status, edits } = await fetch('/props-edit/get', { token })
      if (status && edits) {
        setEdits(edits)
      }
    }

    if (token && !edits) {
      fetchRegexes()
    }
  }, [token, edits])

  const isRemoveable = (edit) => {
    const foundEdits = edits.filter(
      (e) =>
        e.keyString === edit.replaceKey &&
        (edit.replaceValue ? e.valueString === edit.replaceValue : true) &&
        e.uuid !== edit.uuid,
    )
    return foundEdits.length < 1
  }

  const getAncestorCountRecursive = (edit) => {
    const foundEdits = edits.filter(
      (e) =>
        e.replaceKey === edit.keyString &&
        (edit.valueString ? e.replaceValue === edit.valueString : true) &&
        e.uuid !== edit.uuid,
    )

    if (foundEdits.length < 1) return 0
    return foundEdits.reduce((acc, edit) => acc + getAncestorCountRecursive(edit), 0) + 1
  }

  return (
    <StyledHistory>
      <Headline>Erstellte Konfigurationen</Headline>
      {Array.isArray(edits) &&
        edits.map((edit) => (
          <Edit
            key={edit.uuid}
            edit={edit}
            isRemoveable={isRemoveable(edit)}
            ancestorCount={getAncestorCountRecursive(edit)}
          />
        ))}
    </StyledHistory>
  )
}

export default History
