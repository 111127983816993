import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { set } from '../../action/tables'
import fetch from '../../fetch'
import Button from '../UI/Button/Button'
import './Searches.scss'
import { defaultSearches } from '../../helper/seaches'

const Searches = ({ close }) => {
  const token = useSelector((state) => state.token)
  const [searches, setSearches] = useState(null)
  const dispatch = useDispatch()

  useEffect(() => {
    const getSearches = async () => {
      const { status, result } = await fetch('/search/list', { token })
      if (status && result) {
        setSearches([...result, ...defaultSearches])
      }
    }
    if (!searches && token) getSearches()
  }, [token, searches])

  const onClickSearch = (search) => () => {
    for (const table of search.tables) {
      table.items = null
      //   table.columns = []
    }
    dispatch(set(search.tables))
    close()
  }

  const onClickSearchDelete = (search) => async () => {
    const { status } = await fetch('/search/delete', { token, search })
    if (status) close()
  }

  return (
    <div id="modalContentSearches">
      {Array.isArray(searches) &&
        searches.map((search) => (
          <div key={search.uuid} className="search">
            <div className="label">{search.name}</div>
            <div className="btnWrapper">
              <Button text="öffnen" onClick={onClickSearch(search)} fullWidth={false} />
              <Button text="löschen" onClick={onClickSearchDelete(search)} fullWidth={false} />
            </div>
          </div>
        ))}
    </div>
  )
}

export default Searches
