import styled from 'styled-components'

export const StyledKeyValueSelector = styled.div`
  padding-top: 20px;
  border-top: 1px solid black;
  margin-top: 50px;
`

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  gap: 20px;
  margin-top: 20px;
`
export const KeysWrapper = styled.div`
  width: 50%;
`
export const ValueWrapper = styled.div`
  width: 50%;
  max-height: 500px;
  overflow-y: scroll;
`

export const Key = styled.div`
  background-color: ${({ selected }) => (selected ? '#118bdc' : 'transparent')};
  padding: 5px 10px;
  color: ${({ selected }) => (selected ? 'white' : 'black')};
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const Value = styled.div`
  background-color: ${({ selected }) => (selected ? '#118bdc' : 'transparent')};
  padding: 5px 10px;
  color: ${({ selected }) => (selected ? 'white' : 'black')};
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const Title = styled.div`
  font-size: 20px;
  margin-bottom: 10px;
`

export const Headline = styled.div`
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
`
