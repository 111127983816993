import './Login.scss'
import { setUserToken, setUser } from '../../../action/user'
import Cookies from 'universal-cookie'
import fetch from '../../../fetch'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useState } from 'react'
import Layout from '../../Layout/Layout'
import Input from '../../UI/Input/Input'
import Button from '../../UI/Button/Button'

const Login = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const [mail, setMail] = useState('')
  const [password, setPassword] = useState('')
  const [error, setError] = useState(null)
  const translations = useSelector((state) => state.translations)
  const cookies = new Cookies()

  const successfulLogin = (token, user) => {
    dispatch(setUserToken(token))
    dispatch(setUser(user))
    cookies.set('token', token, { path: '/' })
    history.push('/compare')
  }

  const login = async (twoFACode) => {
    if (mail.length > 0 && password.length > 0) {
      setError(null)
      const { status, token, user } = await fetch('/auth/login', {
        mail,
        password,
        twoFAInput: twoFACode,
      })
      if (status && token && user) {
        successfulLogin(token, user)
      } else {
        setError(translations['login.wrong-credentials'])
      }
    } else {
      setError(translations['login.missing-inputs'])
    }
  }

  const submit = (e) => {
    e.preventDefault()
    login()
  }

  return (
    <Layout>
      <div id="login">
        <div className="form-wrapper">
          <div className="headline">{translations['login.headline']}</div>
          <form onSubmit={submit}>
            <div className="element-wrapper">
              <Input type="email" placeholder={translations['login.mail']} value={mail} onChange={setMail} />
            </div>
            <div className="element-wrapper">
              <Input type="password" placeholder={translations['login.password']} value={password} onChange={setPassword} />
            </div>

            {error ? <div className="error">{error}</div> : ''}
            <div className="element-wrapper">
              <Button text={translations['login.button']} type={'submit'} />
            </div>
          </form>
        </div>
      </div>
    </Layout>
  )
}

export default Login
