import { Switch, Route } from "react-router-dom";
import Users from "./components/Admin/Users/Users";
import Settings from "./components/Admin/Settings/Settings";
import Translations from "./components/Admin/Translations/Translations";
import GeoData from "./components/Admin/GeoData/GeoData";

function AdminRouting({ match }) {
  return (
    <Switch>
      {/* USERS */}
      <Route exact={true} path={`${match.path}/users`} component={Users} />
      {/* SETTINGS */}
      <Route exact={true} path={`${match.path}/settings`} component={Settings} />
      {/* TRANSLATIONS */}
      <Route exact={true} path={`${match.path}/translations`} component={Translations} />
      {/* GEO-DATA */}
      <Route exact={true} path={`${match.path}/geo-data`} component={GeoData} />
    </Switch>
  );
}

export default AdminRouting;
