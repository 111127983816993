export const setCompareSelectedProducts = (state, action) => {
  return { ...state, compareSelectedProducts: action.payload }
}

export const setSimilarSelectedProduct = (state, action) => {
  return { ...state, similarSelectedProduct: action.payload }
}

export const setCompareReferenceProduct = (state, action) => {
  return { ...state, compareReferenceProduct: action.payload }
}
