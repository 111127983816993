import { getUUID } from '../../../../utility'
import { useDispatch, useSelector } from 'react-redux'
import { edit } from '../../../../action/tables'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp'
import _ from 'lodash'
import CompareIcon from '../../../../assets/image/Icons/Icon-Vergleichen.png'
import SimilarIcon from '../../../../assets/image/Icons/Icon-aehnliche Type.png'

const TableHeader = ({ tableIndex, columnsToDisplay }) => {
  const dispatch = useDispatch()
  const tables = useSelector((state) => state.tables)
  const sorting = tables[tableIndex].sorting
  const translations = useSelector((state) => state.translations)

  const onClickColumn = (col) => () => {
    const tableCopy = _.cloneDeep(tables[tableIndex])
    tableCopy.sorting = {
      key: col,
      direction: getNextSorting(col),
    }
    dispatch(edit(tableCopy, tableIndex))
  }

  const getNextSorting = (col) => {
    if (sorting.key === col) {
      if (sorting.direction > 0) return -1
      else if (sorting.direction < 0) return 0
      else if (sorting.direction === 0) return 1
    } else return 1
  }

  return (
    <div className="tableHeader" id={`tableHeader-${tableIndex}`}>
      <div className="tableCell center white">
        <div className="imageWrapper" title={translations['table.tooltip.compare']}>
          <img src={CompareIcon} alt="compare" />
        </div>
      </div>
      {tableIndex === 0 && (
        <div className="tableCell center white">
          <div className="imageWrapper" title={translations['table.tooltip.similar']}>
            <img src={SimilarIcon} alt="similar" />
          </div>
        </div>
      )}
      {Array.isArray(columnsToDisplay) &&
        columnsToDisplay.map((col) => (
          <div className="tableCell" key={getUUID()} onClick={onClickColumn(col)}>
            <div className="cellLabel">{col}</div>
            <div>
              <div className="arrows">
                <div
                  className={`arrowWrapper ${
                    sorting.key === col ? (sorting.direction === 1 ? 'selected' : '') : ''
                  }`}
                >
                  <ArrowDropDownIcon fontSize="large" />
                </div>
                <div
                  className={`arrowWrapper ${
                    sorting.key === col ? (sorting.direction === -1 ? 'selected' : '') : ''
                  }`}
                >
                  <ArrowDropUpIcon fontSize="large" />
                </div>
              </div>
              {/* {sorting.key === col ? (
                sorting.direction === 1 ? (
                  <ArrowDropDownIcon />
                ) : (
                  <ArrowDropUpIcon />
                )
              ) : (
                ''
              )} */}
            </div>
          </div>
        ))}
    </div>
  )
}

export default TableHeader
