import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import fetch from '../../../fetch.js'
import usePrevious from '../../../helper/usePrevious.js'
import Input from '../../UI/Input/Input.js'
import {
  StyledKeyValueSelector,
  Wrapper,
  KeysWrapper,
  ValueWrapper,
  Key,
  Value,
  Title,
  Headline,
} from './KeyValueSelector.Styled.js'
import Result from './Result/Result.js'

const KeyValueSelector = () => {
  const [keys, setKeys] = useState(null)
  const [values, setValues] = useState(null)
  const [search, setSearch] = useState('')
  const token = useSelector((state) => state.token)
  const [selectedKey, setSelectedKey] = useState(null)
  const [selectedValue, setSelectedValue] = useState(null)

  const prevSearch = usePrevious(search)
  const prevSelectedKey = usePrevious(selectedKey)

  useEffect(() => {
    const fetchkeys = async () => {
      const { status, keys: newKeys } = await fetch('/props-cache/keys', { token, search })

      if (status && newKeys) setKeys(newKeys)
    }

    const fetchValues = async () => {
      const { status, values: newValues } = await fetch('/props-cache/values', {
        token,
        search,
        keyUUID: selectedKey.uuid,
      })
      if (status && newValues) setValues(newValues)
    }

    if (token && search.length > 2 && prevSearch !== search) fetchkeys()
    if (token && selectedKey && selectedKey !== prevSelectedKey) fetchValues()
  }, [token, keys, search, prevSearch, selectedKey, prevSelectedKey])

  const changeSelectedKey = (key) => {
    setSelectedValue(null)
    setSelectedKey(key)
    setSearch(key.key)
    setKeys([key])
  }

  const onChangeSearch = (value) => {
    if (value.length < 2) {
      setKeys(null)
      setValues(null)
      setSelectedKey(null)
      setSelectedValue(null)
    }
    setSearch(value)
  }

  return (
    <StyledKeyValueSelector>
      <Headline>Konfiguration anlegen</Headline>
      <Input value={search} onChange={onChangeSearch} placeholder="Suche" />
      <Wrapper>
        <KeysWrapper>
          <Title>Key Auswahl</Title>
          {keys &&
            keys.map((key) => (
              <Key
                key={key.uuid}
                onClick={() => changeSelectedKey(key)}
                selected={selectedKey?.uuid === key.uuid}
              >
                <div>{key.key}</div>
                <div>({key.providers})</div>
                {/* {`${key.key} (${key.providers})`} */}
              </Key>
            ))}
        </KeysWrapper>
        <ValueWrapper>
          <Title>Value Auswahl</Title>
          {values &&
            values.map((value) => (
              <Value
                onClick={() => setSelectedValue(selectedValue?.uuid === value.uuid ? null : value)}
                key={value.uuid}
                selected={selectedValue?.uuid === value.uuid}
              >
                <div>{value.value}</div>
                <div>({value.providers})</div>
                {/* {`${value.value} (${value.providers})`} */}
              </Value>
            ))}
        </ValueWrapper>
      </Wrapper>

      <Result values={values} selectedKey={selectedKey} selectedValue={selectedValue} />
    </StyledKeyValueSelector>
  )
}

export default KeyValueSelector
