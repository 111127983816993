import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import fetch from '../../../fetch'
import Layout from '../../Layout/Layout'
import List from '../CompareWrapper/List/List'

const History = () => {
  const { productUUID, producer } = useParams()
  const token = useSelector((state) => state.token)

  const [productList, setProductList] = useState(null)

  const [producerObject, setProducerObject] = useState(null)

  useEffect(() => {
    const getHistory = async () => {
      const { status, result } = await fetch('/product/getHistory', { token, productUUID, producer })
      if (status && result) {
        setProductList({ items: result, producer: producerObject })
      }
    }

    const getProducer = async () => {
      const { status, result } = await fetch('/provider/get', { token })
      if (status && result && Array.isArray(result)) {
        setProducerObject(result.find((p) => p.uuid === producer))
      }
    }

    if (!producerObject && producer && token) getProducer()

    if (productUUID && !productList && token && producerObject) getHistory()
  }, [productUUID, token, productList, producer, producerObject])

  return (
    <Layout authRequired={true}>
      <div>{productList && <List productList={productList} listIndex={100} hideButtons={true} readOnly={true} />}</div>
    </Layout>
  )
}

export default History
