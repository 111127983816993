import * as types from '../type/types'
import * as user from './user'
import * as translations from './translations'
import * as settings from './settings'
import * as newObject from './newObject'
import * as product from './product'
import * as productLists from './productLists'
import * as variantList from './variantList'
import * as selectedProduct from './selectedProduct'
import * as tables from './tables.js'
import * as compareResult from './compareResult'
import defaultState from './defaultState'

function reducer(state = defaultState, action) {
  switch (action.type) {
    case types.SET_USER_TOKEN:
      return user.setToken(state, action)
    case types.SET_USER:
      return user.set(state, action)
    case types.SET_SETTINGS:
      return settings.set(state, action)
    case types.SET_TRANSLATIONS:
      return translations.set(state, action)
    case types.SET_LANG_OPTIONS:
      return translations.setLangOptions(state, action)
    case types.SET_LANG:
      return translations.setLang(state, action)
    case types.SET_NEW_OBJECT:
      return newObject.set(state, action)
    case types.SET_NEW_OBJECT_NAVIGATION_INDEX:
      return newObject.setNavigationIndex(state, action)
    case types.TRIGGER_NEW_OBJECT_CLEANUP:
      return newObject.cleanup(state, action)
    case types.SELECTED_PRODUCT_FOR_COMPARISON:
      return product.set(state, action)
    case types.PRODUCT_LISTS:
      return productLists.set(state, action)
    case types.UPDATE_PRODUCT_LIST:
      return productLists.update(state, action)
    case types.ADD_PRODUCT_LIST:
      return productLists.add(state, action)
    case types.UPDATE_PRODUCT_LIST_ITEMS:
      return productLists.updateItems(state, action)
    case types.SET_VARIANT_LIST:
      return variantList.set(state, action)
    case types.UPDATE_VARIANT_LIST_ITEM:
      return variantList.update(state, action)
    case types.SET_COMPARE_SELECTED_PRODUCTS:
      return selectedProduct.setCompareSelectedProducts(state, action)
    case types.SET_SIMILAR_SELECTED_PRODUCT:
      return selectedProduct.setSimilarSelectedProduct(state, action)
    case types.SET_COMPARE_REFERENCE_PRODUCT:
      return selectedProduct.setCompareReferenceProduct(state, action)
    case types.SET_TABLES:
      return tables.set(state, action)
    case types.ADD_TABLE:
      return tables.add(state, action)
    case types.SET_COMPARE_RESULT:
      return compareResult.setCompareResult(state, action)
    case types.TABLE_REMOVE_INDEX:
      return tables.removeIndex(state, action)
    case types.TABLE_EDIT:
      return tables.edit(state, action)
    default:
      return state
  }
}

export default reducer
