export const set = (state, action) => {
  return { ...state, productLists: action.payload }
}

export const update = (state, action) => {
  const productListsCopy = [...state.productLists]
  productListsCopy[action.payload.index] = action.payload.productList
  return { ...state, productLists: productListsCopy }
}

export const updateItems = (state, action) => {
  const productListsCopy = [...state.productLists]
  productListsCopy[action.payload.index].items = action.payload.items
  return { ...state, productLists: productListsCopy }
}

export const add = (state, action) => {
  return { ...state, productLists: [...state.productLists, action.payload] }
}
