import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import fetch from '../../../fetch'
import Layout from '../../Layout/Layout'

const Translations = () => {
  const [props, setProps] = useState(null)
  const [producer, setProducer] = useState(null)
  const [translations, setTranslations] = useState(null)
  const [isProp, setIsProp] = useState(true)
  const token = useSelector((state) => state.token)

  useEffect(() => {
    const getProps = async () => {
      const { status, props } = await fetch('/props/get', { producer, isProp, token })
      if (status && props) {
        setProps(props)
      }
    }

    const getTranslations = async () => {
      const { status, translations } = await fetch('/propTranslations/get', { producer, isProp, token })
      if (status && translations) {
        setTranslations(translations)
      }
    }

    if (!props) getProps()
    if ((producer, isProp && !translations)) getTranslations()
  }, [isProp, producer, props, translations, token])

  const onChangeProducer = (e) => {
    setProducer(e.target.selectedOptions[0].innerText)
    setProps(null)
  }

  const onChangeTranslation = (key) => (e) => setTranslations([...translations.filter((t) => t.key !== key), { key: key, translation: e.target.value }])

  const onChangeIsProp = (e) => {
    const value = e.target.selectedOptions[0].innerText
    setIsProp(value === 'Props' ? true : false)
    setProps(null)
  }

  const save = async () => {
    const { status } = await fetch('/propTranslations/save', { translations, producer, isProp, token })
    if (status) {
      //ConfirmMessage
    }
  }

  return (
    <Layout authRequired={true}>
      <div>
        <div className="producer">
          <select onChange={onChangeProducer}>
            <option>---bitte Wählen---</option>
            <option>Nexans</option>
            <option>Helukabel</option>
          </select>
        </div>
        <div>
          <select onChange={onChangeIsProp}>
            <option>Props</option>
            <option>Product</option>
          </select>
        </div>
        <div>
          <div onClick={save}>Speichern</div>
        </div>
        <div>
          {props &&
            Array.isArray(props) &&
            props.map((prop) => (
              <div>
                <div>
                  <div>{prop.key}</div>
                  <div>{prop.value}</div>
                </div>
                <input type="text" value={translations.find((t) => t.key === prop.key) ? translations.find((t) => t.key === prop.key).translation : ''} onChange={onChangeTranslation(prop.key)} />
              </div>
            ))}
        </div>
      </div>
    </Layout>
  )
}

export default Translations
