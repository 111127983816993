import { Modal } from 'react-bootstrap'
import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import fetch from '../../../../../../fetch'
import Input from '../../../../../UI/Input/Input'

const NewEditModal = ({ product, handleClose, onClickSave, producer }) => {
  const [edits, setEdits] = useState({})
  const [props, setProps] = useState(null)
  const token = useSelector((state) => state.token)

  useEffect(() => {
    const getProps = async () => {
      const { status, props } = await fetch('/product/props/get', { producer, uuid: product.uuid, token })
      if (status && props) {
        setProps(props)
      }
    }
    if (token && !props) getProps()
  }, [props, token, producer, product])

  const update = (prop) => (key) => (value) => {
    setEdits({ ...edits, [prop.uuid]: { ...edits[prop.uuid], [key]: value, origin: prop, productUUID: product.uuid } })
  }

  return (
    <Modal show={true} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Product bearbeiten</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {Array.isArray(props) &&
          props.map((prop) => (
            <div key={prop.uuid}>
              <Input type="text" placeholder={prop.key} value={edits[prop.uuid] && edits[prop.uuid].key ? edits[prop.uuid].key : prop.key} onChange={update(prop)('key')} />
              <Input type="text" placeholder={prop.value} value={edits[prop.uuid] && edits[prop.uuid].value ? edits[prop.uuid].value : prop.value} onChange={update(prop)('value')} />
            </div>
          ))}
      </Modal.Body>
      <Modal.Footer>
        <div onClick={onClickSave(edits)}>Speichern</div>
      </Modal.Footer>
    </Modal>
  )
}

export default NewEditModal
