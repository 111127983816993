import { useEffect, useState } from 'react'
import Modal from '../../../UI/Modal/Modal'
import { useSelector } from 'react-redux'
import fetch from '../../../../fetch'
import Input from '../../../UI/Input/Input'
import Button from '../../../UI/Button/Button'
import Checkbox from '../../../UI/Checkbox/Checkbox'
import './UserEditModal.scss'

const UserEditModal = ({ showModal, handleClose, userUUID }) => {
  const token = useSelector((state) => state.token)
  const [user, setUser] = useState(null)
  const [changes, setChanges] = useState({})
  const [password, setPassword] = useState('')

  useEffect(() => {
    const getUser = async () => {
      const { status, user } = await fetch('/admin/user/get', { token, userUUID })
      if (status && user) setUser(user)
    }

    if (userUUID && !user) getUser()
  }, [userUUID, token, user])

  const onChangeValue = (key) => (value) => {
    const copy = { ...changes }
    copy[key] = value
    setChanges(copy)
  }

  const onClickSave = async () => {
    const { status } = await fetch('/admin/user/update', { token, changes, userUUID, password })
    if (status) {
      handleClose()
    }
  }

  const onChangePermission = (e) => {
    const copy = { ...changes }
    copy.permissions = e.target.selectedOptions[0].value
    setChanges(copy)
  }

  return (
    <Modal open={showModal} close={handleClose} title="Benutzer bearbeiten">
      <div className="modalContent">
        {user &&
          Object.keys(user).map((key) => (
            <div key={key} className="space">
              {typeof user[key] === 'string' && key !== 'permissions' && (
                <Input
                  type="text"
                  value={changes[key] !== undefined ? changes[key] : user[key]}
                  placeholder={key}
                  onChange={onChangeValue(key)}
                />
              )}

              {key === 'permissions' && (
                <select value={user[key]} onChange={onChangePermission}>
                  <option value="read">Lesen</option>
                  <option value="admin">Admin</option>
                </select>
              )}
              {typeof user[key] === 'boolean' && (
                <Checkbox
                  checked={changes[key] !== undefined ? changes[key] : user[key]}
                  onChange={onChangeValue(key)}
                  text={key}
                />
              )}
            </div>
          ))}
        <div className="space">
          <Input
            type="password"
            value={password}
            onChange={(value) => setPassword(value)}
            placeholder="neues Passwort"
          />
        </div>
        <div className="space">
          <Button onClick={onClickSave} text="Speichern" />
        </div>
      </div>
    </Modal>
  )
}

export default UserEditModal
