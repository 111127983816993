import { useHistory } from "react-router-dom";
import './Link.scss'

const Link = (props) => {
  const history = useHistory();

  const { href, text, children, newTab = false } = props;
  const propIsMissing = () => {
    const requiredProps = ["href"];
    const status = requiredProps.reduce(
      (acc, prop) => (acc === true ? true : props[prop] === undefined),
      false
    );
    return status && (text !== undefined || children !== undefined)
  };

  const trigger = (e) => {
    e.preventDefault();
    if (newTab) {
      window.open(href, '_blank').focus();
    } else {
      history.push(href);
    }
  };

  if (propIsMissing()) return <></>;
  return (
    <a className="custom-link" href={href} onClick={trigger}>
      {text || children}
    </a>
  );
};

export default Link;
