import * as types from "../type/types";

export const setTranslations = (translations) => ({
    type: types.SET_TRANSLATIONS,
    payload: translations
})

export const setLang = (lang) => ({
    type: types.SET_LANG,
    payload: lang
})

export const setLangOptions = (langOptions) => ({
    type: types.SET_LANG_OPTIONS,
    payload: langOptions
})