import defaultState from "./defaultState"

export const set = (state, action) => {
    return {...state, newObject: action.payload}
}

export const setNavigationIndex = (state, action) => {
    return {...state, newObjectNavigationIndex: action.payload}
}

export const cleanup = (state) => {
    return {...state, newObject: defaultState.newObject, newObjectNavigationIndex: 0}
}