import * as types from '../type/types'

export const setCompareSelectedProducts = (array) => ({
  type: types.SET_COMPARE_SELECTED_PRODUCTS,
  payload: array,
})

export const setSimilarSelectedProduct = (item) => ({
  type: types.SET_SIMILAR_SELECTED_PRODUCT,
  payload: item,
})

export const setCompareReferenceProduct = (item) => ({
  type: types.SET_COMPARE_REFERENCE_PRODUCT,
  payload: item,
})
