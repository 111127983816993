import VisibilitySensor from 'react-visibility-sensor'

const VisibilityChecker = ({ children, onChange }) => {
  return (
    <VisibilitySensor partialVisibility onChange={onChange}>
      <div>{children}</div>

      {/* <Button text="mehr laden" onClick={() => onChange(true)} /> */}
    </VisibilitySensor>
  )
}

export default VisibilityChecker
