import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import fetch from "../../../fetch"
import './GeoData.scss'
import AdminLayout from "../../Layout/Admin/Admin"
import CardHeader from '../../Layout/Dashboard/CardHeader/CardHeader'
import CardContent from '../../Layout/Dashboard/CardContent/CardContent'
import ExportImport from './ExportImport/ExportImport'

const GeoData = () => {

    const token = useSelector(state => state.token)
    const translations = useSelector(state => state.translations)
    const [list, setList] = useState(null)

    useEffect(() => {
        const loadTranslations = async () => {
            const { status, locations } = await fetch('/admin/geo-data/get-all', { token })
            if (status && Array.isArray(locations)) {
                setList(locations)
            }
        }

        if (!list && token) {
            loadTranslations()
        }
    }, [list, token])

    return <AdminLayout card={true}>
        <CardHeader
            headline={translations["layout-admin.navigation.settings.geo-data"]}
        />
        <CardContent>
            <div id="admin-geo-data">
                <ExportImport />
            </div>
        </CardContent>
    </AdminLayout>
}

export default GeoData