import { getUUID } from '../../../../utility'
import CustomCheckbox from '../Checkboxes/CustomCheckbox'
import IconChecked from '../../../../assets/image/Icons/iconChecked.png'
import IconReference from '../../../../assets/image/Icons/iconReference.png'
import {
  setCompareReferenceProduct,
  setSimilarSelectedProduct,
} from '../../../../action/selectedProduct'
import { useDispatch, useSelector } from 'react-redux'
import _ from 'lodash'
import { edit, set } from '../../../../action/tables'

const TableRow = ({ tableIndex, item, i, columnsToDisplay }) => {
  const tables = useSelector((state) => state.tables)
  const table = tables[tableIndex]
  const compareSelectedProducts = table.compareSelectedProducts
  const compareReferenceProduct = useSelector((state) => state.compareReferenceProduct)
  const similarSelectedProduct = useSelector((state) => state.similarSelectedProduct)
  const dispatch = useDispatch()
  const checkedCompare = (item) => () => {
    if (compareSelectedProducts.length === 0 && compareReferenceProduct === null) {
      dispatch(setCompareReferenceProduct(item))
    } else if (compareReferenceProduct.uuid === item.uuid) {
      dispatch(setCompareReferenceProduct(null))
      const tablesCopy = _.cloneDeep(tables)
      for (const table of tablesCopy) {
        table.compareSelectedProducts = []
      }
      dispatch(set(tablesCopy))
    } else if (item.uuid !== compareReferenceProduct?.uuid) {
      const tableCopy = _.cloneDeep(table)
      dispatch(
        edit(
          {
            ...tableCopy,
            compareSelectedProducts: !!compareSelectedProducts.find((el) => el.uuid === item.uuid)
              ? compareSelectedProducts.filter((el) => el.uuid !== item.uuid)
              : [...compareSelectedProducts, item],
          },
          tableIndex,
        ),
      )
    }
  }

  const checkedSimilar = (item) => () =>
    dispatch(setSimilarSelectedProduct(similarSelectedProduct?.uuid === item.uuid ? null : item))

  const compare = (key) => {
    if (compareReferenceProduct && compareSelectedProducts.find((el) => el.uuid === item.uuid)) {
      return item[key] === compareReferenceProduct[key]
    } else if (compareReferenceProduct?.uuid === item.uuid) {
      return true
    } else if (similarSelectedProduct?.uuid === item.uuid) {
      return true
    }

    return false
  }

  return (
    <div className="tableRow" id={`tableRow-${i}`} key={getUUID()}>
      <div className="tableCell center">
        <CustomCheckbox
          onClick={checkedCompare(item)}
          checkedIcon={compareReferenceProduct?.uuid === item.uuid ? IconReference : IconChecked}
          checked={
            compareReferenceProduct?.uuid === item.uuid ||
            !!compareSelectedProducts?.find((el) => el.uuid === item.uuid)
          }
        />
      </div>
      {tableIndex === 0 && (
        <div className="tableCell center">
          <CustomCheckbox
            onClick={checkedSimilar(item)}
            checkedIcon={IconChecked}
            checked={similarSelectedProduct?.uuid === item.uuid}
          />
        </div>
      )}
      {columnsToDisplay.map((key) => (
        <div className={`tableCell ${compare(key) ? 'isSame' : ''}`} key={getUUID()}>
          {item[key]}
        </div>
      ))}
    </div>
  )
}

export default TableRow
