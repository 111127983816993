export const set = (state, action) => {
  return { ...state, tables: action.payload }
}

export const add = (state, action) => {
  return { ...state, tables: [...state.tables, action.payload] }
}

export const edit = (state, action) => {
  return {
    ...state,
    tables: state.tables.map((table, i) =>
      i === action.payload.tableIndex ? action.payload.table : table,
    ),
  }
}

export const removeIndex = (state, action) => {
  return { ...state, tables: state.tables.filter((el, i) => i !== action.payload) }
}
