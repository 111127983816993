import { useSelector } from 'react-redux'
import { StyledLib, Name, Result, Key, Value } from './Edit.Styled.js'
import Button from '../../../UI/Button/Button.js'
import fetch from '../../../../fetch.js'

const Edit = ({ edit, isRemoveable, ancestorCount }) => {
  const token = useSelector((s) => s.token)
  const getName = () => {
    let name = ''
    if (edit?.keyString) {
      name = name + edit.keyString
    }
    if (edit?.valueString) {
      name = name + ' - ' + edit.valueString
    }

    return name
  }

  const name = getName()

  const remove = async () => {
    await fetch('/props-edit/remove', { token, editUUID: edit.uuid })
    window.location.replace('/configurator')
  }

  return (
    <StyledLib ancestorCount={ancestorCount}>
      <Name>{name}</Name>
      <Result>
        <Key>Key: {edit?.replaceKey}</Key>
        <Value>Value: {edit?.replaceValue || 'ALLE WERTE'}</Value>
      </Result>
      {isRemoveable && <Button onClick={remove} text="Löschen" />}
    </StyledLib>
  )
}

export default Edit
