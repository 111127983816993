import * as types from '../type/types'

export const set = (array) => ({
  type: types.SET_TABLES,
  payload: array,
})

export const add = (table) => ({
  type: types.ADD_TABLE,
  payload: table,
})

export const removeIndex = (index) => ({
  type: types.TABLE_REMOVE_INDEX,
  payload: index,
})

export const edit = (table, tableIndex) => ({
  type: types.TABLE_EDIT,
  payload: { table, tableIndex },
})
