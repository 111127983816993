import React, { Component } from "react";
import Container from "react-bootstrap/Container";
import "./DataProtection.scss";

class DataProtection extends Component {
  render() {
    return (
      <div id="data-protection" className="default-padding">
        <Container>
          <div className="headline">
            Datenschutzerklärung
          </div>
            <p>
              Diese Datenschutzerklärung klärt Sie über die Art, den Umfang und
              Zweck der Verarbeitung von personenbezogenen Daten (nachfolgend
              kurz „Daten“) innerhalb unseres Onlineangebotes und der mit ihm
              verbundenen Webseiten, Funktionen und Inhalte sowie externen
              Onlinepräsenzen, wie z.B. unser Social Media Profile auf.
              (nachfolgend gemeinsam bezeichnet als „Onlineangebot“). Im
              Hinblick auf die verwendeten Begrifflichkeiten, wie z.B.
              „Verarbeitung“ oder „Verantwortlicher“ verweisen wir auf die
              Definitionen im Art. 4 der Datenschutzgrundverordnung (DSGVO).
            </p>
              <div className="subheadline">Verantwortlicher</div>
            <p>
              Think Internet GmbH
              <br />
              Kontakt – Datenschutzbeauftragter:&nbsp;
                <a href="mailto:datenschutz@freyer-berater.de">
                  datenschutz@freyer-berater.de
                </a>
            </p>
              <div className="subheadline">Arten der verarbeiteten Daten:</div>
            <p>
              – Bestandsdaten (z.B. Namen, Adressen).
              <br />
              – Kontaktdaten (z.B. E-Mail, Telefonnummern).
              <br />
              – Inhaltsdaten (z.B. Texteingaben, Fotografien, Videos).
              <br />
              – Nutzungsdaten (z.B. besuchte Webseiten, Interesse an Inhalten,
              Zugriffszeiten).
              <br />– Meta-/Kommunikationsdaten (z.B. Geräte-Informationen,
              IP-Adressen).
            </p>
              <div className="subheadline">Zweck der Verarbeitung</div>
            <p>
              – Zurverfügungstellung des Onlineangebotes, seiner Funktionen und
              Inhalte.
              <br />
              – Beantwortung von Kontaktanfragen und Kommunikation mit Nutzern.
              <br />
              – Sicherheitsmaßnahmen.
              <br />– Reichweitenmessung/Marketing
            </p>
              <div className="subheadline">Verwendete Begrifflichkeiten</div>
            <p>
              „Personenbezogene Daten“ sind alle Informationen, die sich auf
              eine identifizierte oder identifizierbare natürliche Person (im
              Folgenden „betroffene Person“) beziehen; als identifizierbar wird
              eine natürliche Person angesehen, die direkt oder indirekt,
              insbesondere mittels Zuordnung zu einer Kennung wie einem Namen,
              zu einer Kennnummer, zu Standortdaten, zu einer Online-Kennung
              (z.B. Cookie) oder zu einem oder mehreren besonderen Merkmalen
              identifiziert werden kann, die Ausdruck der physischen,
              physiologischen, genetischen, psychischen, wirtschaftlichen,
              kulturellen oder sozialen Identität dieser natürlichen Person
              sind.
            </p>
            <p>
              „Verarbeitung“ ist jeder mit oder ohne Hilfe automatisierter
              Verfahren ausgeführten Vorgang oder jede solche Vorgangsreihe im
              Zusammenhang mit personenbezogenen Daten. Der Begriff reicht weit
              und umfasst praktisch jeden Umgang mit Daten.
            </p>
            <p>
              Als „Verantwortlicher“ wird die natürliche oder juristische
              Person, Behörde, Einrichtung oder andere Stelle, die allein oder
              gemeinsam mit anderen über die Zwecke und Mittel der Verarbeitung
              von personenbezogenen Daten entscheidet, bezeichnet.
            </p>
              <div className="subheadline">Maßgebliche Rechtsgrundlagen</div>
            <p>
              Nach Maßgabe des Art. 13 DSGVO teilen wir Ihnen die
              Rechtsgrundlagen unserer Datenverarbeitungen mit. Sofern die
              Rechtsgrundlage in der Datenschutzerklärung nicht genannt wird,
              gilt Folgendes: Die Rechtsgrundlage für die Einholung von
              Einwilligungen ist Art. 6 Abs. 1 lit. a und Art. 7 DSGVO, die
              Rechtsgrundlage für die Verarbeitung zur Erfüllung unserer
              Leistungen und Durchführung vertraglicher Maßnahmen sowie
              Beantwortung von Anfragen ist Art. 6 Abs. 1 lit. b DSGVO, die
              Rechtsgrundlage für die Verarbeitung zur Erfüllung unserer
              rechtlichen Verpflichtungen ist Art. 6 Abs. 1 lit. c DSGVO, und
              die Rechtsgrundlage für die Verarbeitung zur Wahrung unserer
              berechtigten Interessen ist Art. 6 Abs. 1 lit. f DSGVO. Für den
              Fall, dass lebenswichtige Interessen der betroffenen Person oder
              einer anderen natürlichen Person eine Verarbeitung
              personenbezogener Daten erforderlich machen, dient Art. 6 Abs. 1
              lit. d DSGVO als Rechtsgrundlage.
            </p>
              <div className="subheadline">Sicherheitsmaßnahmen</div>
            <p>
              Wir bitten Sie sich regelmäßig über den Inhalt unserer
              Datenschutzerklärung zu informieren. Wir passen die
              Datenschutzerklärung an, sobald die Änderungen der von uns
              durchgeführten Datenverarbeitungen dies erforderlich machen. Wir
              informieren Sie, sobald durch die Änderungen eine
              Mitwirkungshandlung Ihrerseits (z.B. Einwilligung) oder eine
              sonstige individuelle Benachrichtigung erforderlich wird.
            </p>
              <div className="subheadline">
                Zusammenarbeit mit Auftragsverarbeitern und Dritten
              </div>
            <p>
              Sofern wir im Rahmen unserer Verarbeitung Daten gegenüber anderen
              Personen und Unternehmen (Auftragsverarbeitern oder Dritten)
              offenbaren, sie an diese übermitteln oder ihnen sonst Zugriff auf
              die Daten gewähren, erfolgt dies nur auf Grundlage einer
              gesetzlichen Erlaubnis (z.B. wenn eine Übermittlung der Daten an
              Dritte, wie an Zahlungsdienstleister, gem. Art. 6 Abs. 1 lit. b
              DSGVO zur Vertragserfüllung erforderlich ist), Sie eingewilligt
              haben, eine rechtliche Verpflichtung dies vorsieht oder auf
              Grundlage unserer berechtigten Interessen (z.B. beim Einsatz von
              Beauftragten, Webhostern, etc.).
            </p>
            <p>
              Sofern wir Dritte mit der Verarbeitung von Daten auf Grundlage
              eines sog. „Auftragsverarbeitungsvertrages“ beauftragen, geschieht
              dies auf Grundlage des Art. 28 DSGVO.
            </p>
              <div className="subheadline">Übermittlungen in Drittländer</div>
            <p>
              Sofern wir Daten in einem Drittland (d.h. außerhalb der
              Europäischen Union (EU) oder des Europäischen Wirtschaftsraums
              (EWR)) verarbeiten oder dies im Rahmen der Inanspruchnahme von
              Diensten Dritter oder Offenlegung, bzw. Übermittlung von Daten an
              Dritte geschieht, erfolgt dies nur, wenn es zur Erfüllung unserer
              (vor)vertraglichen Pflichten, auf Grundlage Ihrer Einwilligung,
              aufgrund einer rechtlichen Verpflichtung oder auf Grundlage
              unserer berechtigten Interessen geschieht. Vorbehaltlich
              gesetzlicher oder vertraglicher Erlaubnisse, verarbeiten oder
              lassen wir die Daten in einem Drittland nur beim Vorliegen der
              besonderen Voraussetzungen der Art. 44 ff. DSGVO verarbeiten. D.h.
              die Verarbeitung erfolgt z.B. auf Grundlage besonderer Garantien,
              wie der offiziell anerkannten Feststellung eines der EU
              entsprechenden Datenschutzniveaus (z.B. für die USA durch das
              „Privacy Shield“) oder Beachtung offiziell anerkannter spezieller
              vertraglicher Verpflichtungen (so genannte
              „Standardvertragsklauseln“).
            </p>
              <div className="subheadline">Rechte der betroffenen Personen</div>
            <p>
              Sie haben das Recht, eine Bestätigung darüber zu verlangen, ob
              betreffende Daten verarbeitet werden und auf Auskunft über diese
              Daten sowie auf weitere Informationen und Kopie der Daten
              entsprechend Art. 15 DSGVO.
            </p>
            <p>
              Sie haben entsprechend. Art. 16 DSGVO das Recht, die
              Vervollständigung der Sie betreffenden Daten oder die Berichtigung
              der Sie betreffenden unrichtigen Daten zu verlangen.
            </p>
            <p>
              Sie haben nach Maßgabe des Art. 17 DSGVO das Recht zu verlangen,
              dass betreffende Daten unverzüglich gelöscht werden, bzw.
              alternativ nach Maßgabe des Art. 18 DSGVO eine Einschränkung der
              Verarbeitung der Daten zu verlangen.
            </p>
            <p>
              Sie haben das Recht zu verlangen, dass die Sie betreffenden Daten,
              die Sie uns bereitgestellt haben nach Maßgabe des Art. 20 DSGVO zu
              erhalten und deren Übermittlung an andere Verantwortliche zu
              fordern.
            </p>
            <p>
              Sie haben ferner gem. Art. 77 DSGVO das Recht, eine Beschwerde bei
              der zuständigen Aufsichtsbehörde einzureichen.
            </p>
              <div className="subheadline">Widerrufsrecht</div>
            <p>
              Sie haben das Recht, erteilte Einwilligungen gem. Art. 7 Abs. 3
              DSGVO mit Wirkung für die Zukunft zu widerrufen
            </p>
              <div className="subheadline">Widerspruchsrecht</div>
            <p>
              Sie können der künftigen Verarbeitung der Sie betreffenden Daten
              nach Maßgabe des Art. 21 DSGVO jederzeit widersprechen. Der
              Widerspruch kann insbesondere gegen die Verarbeitung für Zwecke
              der Direktwerbung erfolgen.
            </p>
              <div className="subheadline">Cookies und Widerspruchsrecht bei Direktwerbung</div>
            <p>
              Als „Cookies“ werden kleine Dateien bezeichnet, die auf Rechnern
              der Nutzer gespeichert werden. Innerhalb der Cookies können
              unterschiedliche Angaben gespeichert werden. Ein Cookie dient
              primär dazu, die Angaben zu einem Nutzer (bzw. dem Gerät auf dem
              das Cookie gespeichert ist) während oder auch nach seinem Besuch
              innerhalb eines Onlineangebotes zu speichern. Als temporäre
              Cookies, bzw. „Session-Cookies“ oder „transiente Cookies“, werden
              Cookies bezeichnet, die gelöscht werden, nachdem ein Nutzer ein
              Onlineangebot verlässt und seinen Browser schließt. In einem
              solchen Cookie kann z.B. der Inhalt eines Warenkorbs in einem
              Onlineshop oder ein Login-Staus gespeichert werden. Als
              „permanent“ oder „persistent“ werden Cookies bezeichnet, die auch
              nach dem Schließen des Browsers gespeichert bleiben. So kann z.B.
              der Login-Status gespeichert werden, wenn die Nutzer diese nach
              mehreren Tagen aufsuchen. Ebenso können in einem solchen Cookie
              die Interessen der Nutzer gespeichert werden, die für
              Reichweitenmessung oder Marketingzwecke verwendet werden. Als
              „Third-Party-Cookie“ werden Cookies bezeichnet, die von anderen
              Anbietern als dem Verantwortlichen, der das Onlineangebot
              betreibt, angeboten werden (andernfalls, wenn es nur dessen
              Cookies sind spricht man von „First-Party Cookies“).
            </p>
            <p>
              Wir können temporäre und permanente Cookies einsetzen und klären
              hierüber im Rahmen unserer Datenschutzerklärung auf.
            </p>
            <p>
              Falls die Nutzer nicht möchten, dass Cookies auf ihrem Rechner
              gespeichert werden, werden sie gebeten die entsprechende Option in
              den Systemeinstellungen ihres Browsers zu deaktivieren.
              Gespeicherte Cookies können in den Systemeinstellungen des
              Browsers gelöscht werden. Der Ausschluss von Cookies kann zu
              Funktionseinschränkungen dieses Onlineangebotes führen.
            </p>
            <p>
              Ein genereller Widerspruch gegen den Einsatz der zu Zwecken des
              Onlinemarketing eingesetzten Cookies kann bei einer Vielzahl der
              Dienste, vor allem im Fall des Trackings, über die
              US-amerikanische Seite http://www.aboutads.info/choices/oder die
              EU-Seite http://www.youronlinechoices.com/ erklärt werden. Des
              Weiteren kann die Speicherung von Cookies mittels deren
              Abschaltung in den Einstellungen des Browsers erreicht werden.
              Bitte beachten Sie, dass dann gegebenenfalls nicht alle Funktionen
              dieses Onlineangebotes genutzt werden können.
            </p>
              <div className="subheadline">Löschung von Daten</div>
            <p>
              Die von uns verarbeiteten Daten werden nach Maßgabe der Art. 17
              und 18 DSGVO gelöscht oder in ihrer Verarbeitung eingeschränkt.
              Sofern nicht im Rahmen dieser Datenschutzerklärung ausdrücklich
              angegeben, werden die bei uns gespeicherten Daten gelöscht, sobald
              sie für ihre Zweckbestimmung nicht mehr erforderlich sind und der
              Löschung keine gesetzlichen Aufbewahrungspflichten entgegenstehen.
              Sofern die Daten nicht gelöscht werden, weil sie für andere und
              gesetzlich zulässige Zwecke erforderlich sind, wird deren
              Verarbeitung eingeschränkt. D.h. die Daten werden gesperrt und
              nicht für andere Zwecke verarbeitet. Das gilt z.B. für Daten, die
              aus handels- oder steuerrechtlichen Gründen aufbewahrt werden
              müssen.
            </p>
            <p>
              Nach gesetzlichen Vorgaben in Deutschland, erfolgt die
              Aufbewahrung insbesondere für 10 Jahre gemäß §§ 147 Abs. 1 AO, 257
              Abs. 1 Nr. 1 und 4, Abs. 4 HGB (Bücher, Aufzeichnungen,
              Lageberichte, Buchungsbelege, Handelsbücher, für Besteuerung
              relevanter Unterlagen, etc.) und 6 Jahre gemäß § 257 Abs. 1 Nr. 2
              und 3, Abs. 4 HGB (Handelsbriefe).
            </p>
            <p>
              Nach gesetzlichen Vorgaben in Österreich erfolgt die Aufbewahrung
              insbesondere für 7 J gemäß § 132 Abs. 1 BAO
              (Buchhaltungsunterlagen, Belege/Rechnungen, Konten, Belege,
              Geschäftspapiere, Aufstellung der Einnahmen und Ausgaben, etc.),
              für 22 Jahre im Zusammenhang mit Grundstücken und für 10 Jahre bei
              Unterlagen im Zusammenhang mit elektronisch erbrachten Leistungen,
              Telekommunikations-, Rundfunk- und Fernsehleistungen, die an
              Nichtunternehmer in EU-Mitgliedstaaten erbracht werden und für die
              der Mini-One-Stop-Shop (MOSS) in Anspruch genommen wird.
            </p>
              <div className="subheadline">Hosting</div>
            <p>
              Die von uns in Anspruch genommenen Hosting-Leistungen dienen der
              Zurverfügungstellung der folgenden Leistungen: Infrastruktur- und
              Plattformdienstleistungen, Rechenkapazität, Speicherplatz und
              Datenbankdienste, Sicherheitsleistungen sowie technische
              Wartungsleistungen, die wir zum Zwecke des Betriebs dieses
              Onlineangebotes einsetzen.
            </p>
            <p>
              Hierbei verarbeiten wir, bzw. unser Hostinganbieter Bestandsdaten,
              Kontaktdaten, Inhaltsdaten, Vertragsdaten, Nutzungsdaten, Meta-
              und Kommunikationsdaten von Kunden, Interessenten und Besuchern
              dieses Onlineangebotes auf Grundlage unserer berechtigten
              Interessen an einer effizienten und sicheren Zurverfügungstellung
              dieses Onlineangebotes gem. Art. 6 Abs. 1 lit. f DSGVO i.V.m. Art.
              28 DSGVO (Abschluss Auftragsverarbeitungsvertrag).
            </p>
              <div className="subheadline">Agenturdienstleistungen</div>
            <p>
              Wir verarbeiten die Daten unserer Kunden im Rahmen unserer
              vertraglichen Leistungen zu denen konzeptionelle und strategische
              Beratung, Kampagnenplanung, Software- und
              Designentwicklung/-beratung oder Pflege, Umsetzung von Kampagnen
              und Prozessen/ Handling, Serveradministration, Datenanalyse/
              Beratungsleistungen und Schulungsleistungen gehören.
            </p>
            <p>
              Hierbei verarbeiten wir Bestandsdaten (z.B., Kundenstammdaten, wie
              Namen oder Adressen), Kontaktdaten (z.B. E-Mail, Telefonnummern),
              Inhaltsdaten (z.B. Texteingaben, Fotografien, Videos),
              Vertragsdaten (z.B. Vertragsgegenstand, Laufzeit), Zahlungsdaten
              (z.B., Bankverbindung, Zahlungshistorie), Nutzungs- und Metadaten
              (z.B. im Rahmen der Auswertung und Erfolgsmessung von
              Marketingmaßnahmen). Besondere Kategorien personenbezogener Daten
              verarbeiten wir grundsätzlich nicht, außer wenn diese Bestandteile
              einer beauftragten Verarbeitung sind. Zu den Betroffenen gehören
              unsere Kunden, Interessenten sowie deren Kunden, Nutzer,
              Websitebesucher oder Mitarbeiter sowie Dritte. Der Zweck der
              Verarbeitung besteht in der Erbringung von Vertragsleistungen,
              Abrechnung und unserem Kundenservice. Die Rechtsgrundlagen der
              Verarbeitung ergeben sich aus Art. 6 Abs. 1 lit. b DSGVO
              (vertragliche Leistungen), Art. 6 Abs. 1 lit. f DSGVO (Analyse,
              Statistik, Optimierung, Sicherheitsmaßnahmen). Wir verarbeiten
              Daten, die zur Begründung und Erfüllung der vertraglichen
              Leistungen erforderlich sind und weisen auf die Erforderlichkeit
              ihrer Angabe hin. Eine Offenlegung an Externe erfolgt nur, wenn
              sie im Rahmen eines Auftrags erforderlich ist. Bei der
              Verarbeitung der uns im Rahmen eines Auftrags überlassenen Daten
              handeln wir entsprechend den Weisungen der Auftraggeber sowie der
              gesetzlichen Vorgaben einer Auftragsverarbeitung gem. Art. 28
              DSGVO und verarbeiten die Daten zu keinen anderen, als den
              auftragsgemäßen Zwecken.
            </p>
            <p>
              Wir löschen die Daten nach Ablauf gesetzlicher Gewährleistungs-
              und vergleichbarer Pflichten. die Erforderlichkeit der
              Aufbewahrung der Daten wird alle drei Jahre überprüft; im Fall der
              gesetzlichen Archivierungspflichten erfolgt die Löschung nach
              deren Ablauf (6 J, gem. § 257 Abs. 1 HGB, 10 J, gem. § 147 Abs. 1
              AO). Im Fall von Daten, die uns gegenüber im Rahmen eines Auftrags
              durch den Auftraggeber offengelegt wurden, löschen wir die Daten
              entsprechend den Vorgaben des Auftrags, grundsätzlich nach Ende
              des Auftrags.
            </p>
              <div className="subheadline">
                Administration, Finanzbuchhaltung, Büroorganisation,
                Kontaktverwaltung
              </div>
            <p>
              Wir verarbeiten Daten im Rahmen von Verwaltungsaufgaben sowie
              Organisation unseres Betriebs, Finanzbuchhaltung und Befolgung der
              gesetzlichen Pflichten, wie z.B. der Archivierung. Herbei
              verarbeiten wir dieselben Daten, die wir im Rahmen der Erbringung
              unserer vertraglichen Leistungen verarbeiten. Die
              Verarbeitungsgrundlagen sind Art. 6 Abs. 1 lit. c. DSGVO, Art. 6
              Abs. 1 lit. f. DSGVO. Von der Verarbeitung sind Kunden,
              Interessenten, Geschäftspartner und Websitebesucher betroffen. Der
              Zweck und unser Interesse an der Verarbeitung liegt in der
              Administration, Finanzbuchhaltung, Büroorganisation, Archivierung
              von Daten, also Aufgaben die der Aufrechterhaltung unserer
              Geschäftstätigkeiten, Wahrnehmung unserer Aufgaben und Erbringung
              unserer Leistungen dienen. Die Löschung der Daten im Hinblick auf
              vertragliche Leistungen und die vertragliche Kommunikation
              entspricht den, bei diesen Verarbeitungstätigkeiten genannten
              Angaben.
            </p>
            <p>
              Wir offenbaren oder übermitteln hierbei Daten an die
              Finanzverwaltung, Berater, wie z.B. Steuerberater oder
              Wirtschaftsprüfer sowie weitere Gebührenstellen und
              Zahlungsdienstleister.
            </p>
            <p>
              Ferner speichern wir auf Grundlage unserer
              betriebswirtschaftlichen Interessen Angaben zu Lieferanten,
              Veranstaltern und sonstigen Geschäftspartnern, z.B. zwecks
              späterer Kontaktaufnahme. Diese mehrheitlich unternehmensbezogenen
              Daten, speichern wir grundsätzlich dauerhaft.
            </p>
              <div className="subheadline">
                Betriebswirtschaftliche Analysen und Marktforschung
              </div>
            <p>
              Um unser Geschäft wirtschaftlich betreiben, Markttendenzen,
              Kunden- und Nutzerwünsche erkennen zu können, analysieren wir die
              uns vorliegenden Daten zu Geschäftsvorgängen, Verträgen, Anfragen,
              etc. Wir verarbeiten dabei Bestandsdaten, Kommunikationsdaten,
              Vertragsdaten, Zahlungsdaten, Nutzungsdaten, Metadaten auf
              Grundlage des Art. 6 Abs. 1 lit. f. DSGVO, wobei zu den
              betroffenen Personen Kunden, Interessenten, Geschäftspartner,
              Besucher und Nutzer des Onlineangebotes gehören.
            </p>
            <p>
              Die Analysen erfolgen zum Zweck betriebswirtschaftlicher
              Auswertungen, des Marketings und der Marktforschung. Dabei können
              wir die Profile der registrierten Nutzer mit Angaben z.B. zu deren
              Kaufvorgängen berücksichtigen. Die Analysen dienen uns zur
              Steigerung der Nutzerfreundlichkeit, der Optimierung unseres
              Angebotes und der Betriebswirtschaftlichkeit. Die Analysen dienen
              alleine uns und werden nicht extern offenbart, sofern es sich
              nicht um anonyme Analysen mit zusammengefassten Werten handelt.
            </p>
            <p>
              Sofern diese Analysen oder Profile personenbezogen sind, werden
              sie mit Kündigung der Nutzer gelöscht oder anonymisiert, sonst
              nach zwei Jahren ab Vertragsschluss. Im Übrigen werden die
              gesamtbetriebswirtschaftlichen Analysen und allgemeine
              Tendenzbestimmungen nach Möglichkeit anonym erstellt.
            </p>
              <div className="subheadline">Datenschutzhinweise im Bewerbungsverfahren</div>
            <p>
              Wir verarbeiten die Bewerberdaten nur zum Zweck und im Rahmen des
              Bewerbungsverfahrens im Einklang mit den gesetzlichen Vorgaben.
              Die Verarbeitung der Bewerberdaten erfolgt zur Erfüllung unserer
              (vor)vertraglichen Verpflichtungen im Rahmen des
              Bewerbungsverfahrens im Sinne des Art. 6 Abs. 1 lit. b. DSGVO Art.
              6 Abs. 1 lit. f. DSGVO sofern die Datenverarbeitung z.B. im Rahmen
              von rechtlichen Verfahren für uns erforderlich wird (in
              Deutschland gilt zusätzlich § 26 BDSG).
            </p>
            <p>
              Das Bewerbungsverfahren setzt voraus, dass Bewerber uns die
              Bewerberdaten mitteilen. Die notwendigen Bewerberdaten sind,
              sofern wir ein Onlineformular anbieten gekennzeichnet, ergeben
              sich sonst aus den Stellenbeschreibungen und grundsätzlich gehören
              dazu die Angaben zur Person, Post- und Kontaktadressen und die zur
              Bewerbung gehörenden Unterlagen, wie Anschreiben, Lebenslauf und
              die Zeugnisse. Daneben können uns Bewerber freiwillig zusätzliche
              Informationen mitteilen.
            </p>
            <p>
              Mit der Übermittlung der Bewerbung an uns, erklären sich die
              Bewerber mit der Verarbeitung ihrer Daten zu Zwecken des
              Bewerbungsverfahrens entsprechend der in dieser
              Datenschutzerklärung dargelegten Art und Umfang einverstanden.
            </p>
            <p>
              Soweit im Rahmen des Bewerbungsverfahrens freiwillig besondere
              Kategorien von personenbezogenen Daten im Sinne des Art. 9 Abs. 1
              DSGVO mitgeteilt werden, erfolgt deren Verarbeitung zusätzlich
              nach Art. 9 Abs. 2 lit. b DSGVO (z.B. Gesundheitsdaten, wie z.B.
              Schwerbehinderteneigenschaft oder ethnische Herkunft). Soweit im
              Rahmen des Bewerbungsverfahrens besondere Kategorien von
              personenbezogenen Daten im Sinne des Art. 9 Abs. 1 DSGVO bei
              Bewerbern angefragt werden, erfolgt deren Verarbeitung zusätzlich
              nach Art. 9 Abs. 2 lit. a DSGVO (z.B. Gesundheitsdaten, wenn diese
              für die Berufsausübung erforderlich sind).
            </p>
            <p>
              Sofern zur Verfügung gestellt, können uns Bewerber ihre
              Bewerbungen mittels eines Onlineformulars auf unserer Website
              übermitteln. Die Daten werden entsprechend dem Stand der Technik
              verschlüsselt an uns übertragen.
              <br />
              Ferner können Bewerber uns ihre Bewerbungen via E-Mail
              übermitteln. Hierbei bitten wir jedoch zu beachten, dass E-Mails
              grundsätzlich nicht verschlüsselt versendet werden und die
              Bewerber selbst für die Verschlüsselung sorgen müssen. Wir können
              daher für den Übertragungsweg der Bewerbung zwischen dem Absender
              und dem Empfang auf unserem Server keine Verantwortung übernehmen
              und empfehlen daher eher ein Online-Formular oder den postalischen
              Versand zu nutzen. Denn statt der Bewerbung über das
              Online-Formular und E-Mail, steht den Bewerbern weiterhin die
              Möglichkeit zur Verfügung, uns die Bewerbung auf dem Postweg
              zuzusenden.
            </p>
            <p>
              Die von den Bewerbern zur Verfügung gestellten Daten, können im
              Fall einer erfolgreichen Bewerbung für die Zwecke des
              Beschäftigungsverhältnisses von uns weiterverarbeitet werden.
              Andernfalls, sofern die Bewerbung auf ein Stellenangebot nicht
              erfolgreich ist, werden die Daten der Bewerber gelöscht. Die Daten
              der Bewerber werden ebenfalls gelöscht, wenn eine Bewerbung
              zurückgezogen wird, wozu die Bewerber jederzeit berechtigt sind.
            </p>
            <p>
              Die Löschung erfolgt, vorbehaltlich eines berechtigten Widerrufs
              der Bewerber, nach dem Ablauf eines Zeitraums von sechs Monaten,
              damit wir etwaige Anschlussfragen zu der Bewerbung beantworten und
              unseren Nachweispflichten aus dem Gleichbehandlungsgesetz genügen
              können. Rechnungen über etwaige Reisekostenerstattung werden
              entsprechend den steuerrechtlichen Vorgaben archiviert.
            </p>
              <div className="subheadline">Kontaktaufnahme</div>
            <p>
              Bei der Kontaktaufnahme mit uns (z.B. per Kontaktformular, E-Mail,
              Telefon oder via sozialer Medien) werden die Angaben des Nutzers
              zur Bearbeitung der Kontaktanfrage und deren Abwicklung gem. Art.
              6 Abs. 1 lit. b) DSGVO verarbeitet. Die Angaben der Nutzer können
              in einem Customer-Relationship-Management System (“CRM System”)
              oder vergleichbarer Anfragenorganisation gespeichert werden.
            </p>
            <p>
              Wir löschen die Anfragen, sofern diese nicht mehr erforderlich
              sind. Wir überprüfen die Erforderlichkeit alle zwei Jahre; Ferner
              gelten die gesetzlichen Archivierungspflichten.
            </p>
              <div className="subheadline">Newsletter</div>
            <p>
              Mit den nachfolgenden Hinweisen informieren wir Sie über die
              Inhalte unseres Newsletters sowie das Anmelde-, Versand- und das
              statistische Auswertungsverfahren sowie Ihre Widerspruchsrechte
              auf. Indem Sie unseren Newsletter abonnieren, erklären Sie sich
              mit dem Empfang und den beschriebenen Verfahren einverstanden.
              <br />
              Inhalt des Newsletters: Wir versenden Newsletter, E-Mails und
              weitere elektronische Benachrichtigungen mit werblichen
              Informationen (nachfolgend „Newsletter“) nur mit der Einwilligung
              der Empfänger oder einer gesetzlichen Erlaubnis. Sofern im Rahmen
              einer Anmeldung zum Newsletter dessen Inhalte konkret umschrieben
              werden, sind sie für die Einwilligung der Nutzer maßgeblich. Im
              Übrigen enthalten unsere Newsletter Informationen zu unseren
              Leistungen und uns.
              <br />
              Double-Opt-In und Protokollierung: Die Anmeldung zu unserem
              Newsletter erfolgt in einem sog. Double-Opt-In-Verfahren. D.h. Sie
              erhalten nach der Anmeldung eine E-Mail, in der Sie um die
              Bestätigung Ihrer Anmeldung gebeten werden. Diese Bestätigung ist
              notwendig, damit sich niemand mit fremden E-Mailadressen anmelden
              kann. Die Anmeldungen zum Newsletter werden protokolliert, um den
              Anmeldeprozess entsprechend den rechtlichen Anforderungen
              nachweisen zu können. Hierzu gehört die Speicherung des Anmelde-
              und des Bestätigungszeitpunkts, als auch der IP-Adresse. Ebenso
              werden die Änderungen Ihrer bei dem Versanddienstleister
              gespeicherten Daten protokolliert.
            </p>
            <p>
              Anmeldedaten: Um sich für den Newsletter anzumelden, reicht es
              aus, wenn Sie Ihre E-Mailadresse angeben. Optional bitten wir Sie
              einen Namen, zwecks persönlicher Ansprache im Newsletters
              anzugeben.
            </p>
            <p>
              Deutschland: Der Versand des Newsletters und die mit ihm
              verbundene Erfolgsmessung erfolgt auf Grundlage einer Einwilligung
              der Empfänger gem. Art. 6 Abs. 1 lit. a, Art. 7 DSGVO i.V.m § 7
              Abs. 2 Nr. 3 UWG bzw. auf Grundlage der gesetzlichen Erlaubnis
              gem. § 7 Abs. 3 UWG.
            </p>
            <p>
              Die Protokollierung des Anmeldeverfahrens erfolgt auf Grundlage
              unserer berechtigten Interessen gem. Art. 6 Abs. 1 lit. f DSGVO.
              Unser Interesse richtet sich auf den Einsatz eines
              nutzerfreundlichen sowie sicheren Newslettersystems, das sowohl
              unseren geschäftlichen Interessen dient, als auch den Erwartungen
              der Nutzer entspricht und uns ferner den Nachweis von
              Einwilligungen erlaubt.
            </p>
            <p>
              Kündigung/Widerruf – Sie können den Empfang unseres Newsletters
              jederzeit kündigen, d.h. Ihre Einwilligungen widerrufen. Einen
              Link zur Kündigung des Newsletters finden Sie am Ende eines jeden
              Newsletters. Wir können die ausgetragenen E-Mailadressen bis zu
              drei Jahren auf Grundlage unserer berechtigten Interessen
              speichern bevor wir sie löschen, um eine ehemals gegebene
              Einwilligung nachweisen zu können. Die Verarbeitung dieser Daten
              wird auf den Zweck einer möglichen Abwehr von Ansprüchen
              beschränkt. Ein individueller Löschungsantrag ist jederzeit
              möglich, sofern zugleich das ehemalige Bestehen einer Einwilligung
              bestätigt wird.
            </p>
              <div className="subheadline">Newsletter – Versanddienstleister</div>
            <p>
              Wir versenden unseren mit CleveReach. Der sitzt des Unternehmens
              CleverReach&nbsp; GmbH &amp; Co. KG, ist: Mühlenstr. 43, 26180
              Rastede. CleverReach ist ein Dienst, mit dem der Newsletterversand
              organisiert und analysiert werden kann. Die von Ihnen zwecks
              Newsletterbezug eingegebenen Daten (z.B. E-Mail-Adresse) werden
              auf den Servern von CleverReach in Deutschland bzw. Irland
              gespeichert.
            </p>
            <p>
              Unsere mit CleverReach versandten Newsletter ermöglichen uns die
              Analyse des Verhaltens der Newsletterempfänger. Hierbei kann u. a.
              analysiert werden, wie viele Empfänger die Newsletternachricht
              geöffnet haben und wie oft welcher Link im Newsletter angeklickt
              wurde.
            </p>
            <p>
              Weitere Informationen zur Datenanalyse durch
              CleverReach-Newsletter erhalten Sie unter:&nbsp;
              <a
                href="https://www.cleverreach.com/de/funktionen/reporting-und-tracking/"
                target="_blank"
                rel="noreferrer"
                data-slimstat="5"
              >
                https://www.cleverreach.com/de/funktionen/reporting-und-tracking/
              </a>
              .
            </p>
            <p>
              Die Datenverarbeitung erfolgt auf Grundlage Ihrer Einwilligung
              (Art. 6 Abs. 1 lit. a DSGVO). Sie können diese Einwilligung
              jederzeit widerrufen, indem Sie den Newsletter
              abbestellen.&nbsp;Die von Ihnen zum Zwecke des Newsletter-Bezugs
              bei uns hinterlegten Daten werden von uns bis zu Ihrer Austragung
              aus dem Newsletter gespeichert und nach der Abbestellung des
              Newsletters sowohl von unseren Servern als auch von den Servern
              von CleverReach gelöscht.
            </p>
            <p>
              Näheres entnehmen Sie den Datenschutzbestimmungen von CleverReach
              unter:&nbsp;
              <a
                href="https://www.cleverreach.com/de/datenschutz/"
                target="_blank"
                rel="noreferrer"
                data-slimstat="5"
              >
                https://www.cleverreach.com/de/datenschutz/
              </a>
              .
            </p>
              <div className="subheadline">Onlinepräsenzen in sozialen Medien</div>
            <p>
              Wir unterhalten Onlinepräsenzen innerhalb sozialer Netzwerke und
              Plattformen, um mit den dort aktiven Kunden, Interessenten und
              Nutzern kommunizieren und sie dort über unsere Leistungen
              informieren zu können. Beim Aufruf der jeweiligen Netzwerke und
              Plattformen gelten die Geschäftsbedingungen und die
              Datenverarbeitungsrichtlinien deren jeweiligen Betreiber.
            </p>
            <p>
              Soweit nicht anders im Rahmen unserer Datenschutzerklärung
              angegeben, verarbeiten wir die Daten der Nutzer sofern diese mit
              uns innerhalb der sozialen Netzwerke und Plattformen
              kommunizieren, z.B. Beiträge auf unseren Onlinepräsenzen verfassen
              oder uns Nachrichten zusenden.
            </p>
              <div className="subheadline">Einbindung von Diensten und Inhalten Dritter</div>
            <p>
              Wir setzen innerhalb unseres Onlineangebotes auf Grundlage unserer
              berechtigten Interessen (d.h. Interesse an der Analyse,
              Optimierung und wirtschaftlichem Betrieb unseres Onlineangebotes
              im Sinne des Art. 6 Abs. 1 lit. f. DSGVO) Inhalts- oder
              Serviceangebote von Drittanbietern ein, um deren Inhalte und
              Services, wie z.B. Videos oder Schriftarten einzubinden
              (nachfolgend einheitlich bezeichnet als “Inhalte”).
            </p>
            <p>
              Dies setzt immer voraus, dass die Drittanbieter dieser Inhalte,
              die IP-Adresse der Nutzer wahrnehmen, da sie ohne die IP-Adresse
              die Inhalte nicht an deren Browser senden könnten. Die IP-Adresse
              ist damit für die Darstellung dieser Inhalte erforderlich. Wir
              bemühen uns nur solche Inhalte zu verwenden, deren jeweilige
              Anbieter die IP-Adresse lediglich zur Auslieferung der Inhalte
              verwenden. Drittanbieter können ferner so genannte Pixel-Tags
              (unsichtbare Grafiken, auch als “Web Beacons” bezeichnet) für
              statistische oder Marketingzwecke verwenden. Durch die
              “Pixel-Tags” können Informationen, wie der Besucherverkehr auf den
              Seiten dieser Website ausgewertet werden. Die pseudonymen
              Informationen können ferner in Cookies auf dem Gerät der Nutzer
              gespeichert werden und unter anderem technische Informationen zum
              Browser und Betriebssystem, verweisende Webseiten, Besuchszeit
              sowie weitere Angaben zur Nutzung unseres Onlineangebotes
              enthalten, als auch mit solchen Informationen aus anderen Quellen
              verbunden werden.
            </p>
              <div className="subheadline">Google Fonts</div>
            <p>
              Wir binden die Schriftarten (“Google Fonts”) des Anbieters Google
              LLC, 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA, ein.
              Datenschutzerklärung:{" "}
              <a
                href="https://www.google.com/policies/privacy/"
                target="_blank"
                rel="noreferrer"
              >
                https://www.google.com/policies/privacy/
              </a>
              , Opt-Out:{" "}
              <a
                href="https://adssettings.google.com/authenticated"
                target="_blank"
                rel="noreferrer"
              >
                https://adssettings.google.com/authenticated
              </a>
              .
            </p>
              <div className="subheadline">Google Maps</div>
            <p>
              Wir binden die Landkarten des Dienstes “Google Maps” des Anbieters
              Google LLC, 1600 Amphitheatre Parkway, Mountain View, CA 94043,
              USA, ein. Zu den verarbeiteten Daten können insbesondere
              IP-Adressen und Standortdaten der Nutzer gehören, die jedoch nicht
              ohne deren Einwilligung (im Regelfall im Rahmen der Einstellungen
              ihrer Mobilgeräte vollzogen), erhoben werden. Die Daten können in
              den USA verarbeitet werden. Datenschutzerklärung:{" "}
              <a
                href="https://www.google.com/policies/privacy/"
                target="_blank"
                rel="noreferrer"
              >
                https://www.google.com/policies/privacy/
              </a>
              , Opt-Out:{" "}
              <a
                href="https://adssettings.google.com/authenticated"
                target="_blank"
                rel="noreferrer"
              >
                https://adssettings.google.com/authenticated
              </a>
              .
            </p>
        </Container>
      </div>
    );
  }
}

export default DataProtection;
