import './Item.scss'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import fetch from '../../../../fetch'
import { getUUID } from '../../../../utility'

const Item = ({ setting, applicationOptions }) => {

    const [application, setApplication] = useState(setting.application)
    const [group, setGroup] = useState(setting.group)
    const [value, setValue] = useState(setting.value)
    const [translationKey, setTranslationKey] = useState(setting.translationKey)
    const translations = useSelector(state => state.translations)
    const token = useSelector(state => state.token)
    const [message, setMessage] = useState(null)

    const update = async () => {
        setMessage(null)
        if (value.length > 0 && group.length > 0) {
            const { status } = await fetch('/admin/settings/upsert', { token, application, key: setting.key, group, value, translationKey })
            if (status) {
                setMessage(translations['admin.settings.update-message.success'])
            } else {
                setMessage(translations['admin.settings.update-message.failure'])
            }
        } else {
            setMessage(translations['admin.settings.update-message.failure'])
        }
    }

    return <div className="settings-item">
        <div className="key">{translations['admin.settings.key']}: {setting.key}</div>
        <div className="property">
            <div className="label">{translations['admin.settings.application']}</div>
            <div className="selection">
                {applicationOptions.map(option => {
                    const uuid = getUUID()
                    return <div key={uuid} className="option"><input checked={application === option.value} onChange={e => setApplication(e.target.value)} type="radio" value={option.value} name={`application-${uuid}`} id={`application-${option.value}-${uuid}`} /> <label htmlFor={`application-${option.value}-${uuid}`}>{option.label}</label></div>
                })}
            </div>
        </div>
        <div className="property">
            <div className="label">{translations['admin.settings.group']}</div>
            <input
                type="text"
                value={group}
                placeholder={translations['admin.settings.group']}
                onChange={(e) => setGroup(e.target.value)}
            />
        </div>
        <div className="property">
            <div className="label">{translations['admin.settings.value']}</div>
            <input
                type="text"
                value={value}
                placeholder={translations['admin.settings.value']}
                onChange={(e) => setValue(e.target.value)}
            />
        </div>
        <div className="property">
            <div className="label">{translations['admin.settings.translationkey']}</div>
            <input
                type="text"
                value={translationKey}
                placeholder={translations['admin.settings.translationkey']}
                onChange={(e) => setTranslationKey(e.target.value)}
            />
        </div>
        <div className="update" onClick={update}>{translations['admin.settings.update']}</div>
        {message && <div className="message">{message}</div>}
    </div>
}

export default Item