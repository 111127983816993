import './CustomCheckbox.scss'

const CustomCheckbox = ({ item, onClick, checkedIcon, checked }) => {
  return (
    <div className={`customCheckbox ${checked && 'customCheckboxColor'}`} onClick={onClick}>
      {checked && <img src={checkedIcon} alt="checked" />}
    </div>
  )
}

export default CustomCheckbox
