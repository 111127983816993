export const SET_TRANSLATION = 'set_translation'

export const SET_USER_TOKEN = 'set_user_token'
export const SET_USER = 'set_user'

export const SET_SETTINGS = 'set_settings'

export const SET_TRANSLATIONS = 'set_translations'
export const SET_LANG_OPTIONS = 'set_lang_options'
export const SET_LANG = 'set_lang'

export const SET_NEW_OBJECT = 'set_new_object'
export const SET_NEW_OBJECT_NAVIGATION_INDEX = 'set_new_object_navigation_index'
export const TRIGGER_NEW_OBJECT_CLEANUP = 'trigger_new_object_cleanup'

export const SELECTED_PRODUCT_FOR_COMPARISON = 'selected_product_for_comparision'
export const PRODUCT_LISTS = 'product_lists'
export const UPDATE_PRODUCT_LIST = 'update_product_list'
export const ADD_PRODUCT_LIST = 'add_product_list'
export const UPDATE_PRODUCT_LIST_ITEMS = 'update_product_list_items'

export const SET_VARIANT_LIST = 'set_variant_list'
export const UPDATE_VARIANT_LIST_ITEM = 'update_variant_list_item'

export const SET_COMPARE_SELECTED_PRODUCTS = 'set_compare_selected_products'
export const SET_SIMILAR_SELECTED_PRODUCT = 'set_similar_selected_product'
export const SET_COMPARE_REFERENCE_PRODUCT = 'set_compare_reference_product'

export const SET_TABLES = 'set_tables'
export const ADD_TABLE = 'add_table'
export const SET_COMPARE_RESULT = 'set_compare_result'

export const TABLE_REMOVE_INDEX = 'table_remove_index'
export const TABLE_EDIT = 'table_edit'
