import { useState } from 'react'
import { useSelector } from "react-redux"
import fetch from "../../../../fetch"
import Button from '../../../UI/Button/Button'
import Input from '../../../UI/Input/Input'

const ExportImport = ({ loadSettings }) => {

    const translations = useSelector(state => state.translations)
    const token = useSelector(state => state.token)
    const [exportText, setExportText] = useState('')
    const [exportTextOpen, setExportTextOpen] = useState(false)
    const [importText, setImportText] = useState('')
    const [importTextOpen, setImportTextOpen] = useState(false)
    const [importTranslationsAsSettingsText, setImportTranslationsAsSettingsText] = useState('')
    const [importTranslationsAsSettingsTextOpen, setImportTranslationsAsSettingsTextOpen] = useState(false)
    const [importTranslationsAsSettingsGroup, setImportTranslationsAsSettingsGroup] = useState('')


    const exportSettings = async () => {
        const { status, text } = await fetch('/admin/settings/export', { token })
        if (status && text) {
            setExportText(text)
            setExportTextOpen(true)
        }
    }

    const importSettings = async () => {
        if (importText.trim().length > 0) {
            const { status } = await fetch('/admin/settings/import', { token, text: importText })
            if (status) {
                setImportText('')
                setImportTextOpen(false)
                loadSettings()
            }
        }
    }

    const importTranslationsAsSettings = async () => {
        if (importTranslationsAsSettingsText.trim().length > 0 && importTranslationsAsSettingsGroup.trim().length > 0) {
            const { status } = await fetch('/admin/settings/import-translations-as-settings', { token, group: importTranslationsAsSettingsGroup, text: importTranslationsAsSettingsText })
            if (status) {
                setImportTranslationsAsSettingsGroup('')
                setImportTranslationsAsSettingsText('')
                setImportTranslationsAsSettingsTextOpen(false)
                loadSettings()
            }
        }
    }

    return <div className="import-export">
        <div className="export-wrapper" onClick={exportSettings}>{translations['admin.settings.export']}</div>
        {exportTextOpen && <div className="export-text"><textarea value={exportText} readOnly />
            <div className="controls">
                <Button className="close" text={translations['admin.settings.export.close']} onClick={() => setExportTextOpen(false)} />
            </div>
        </div>}
        <div className="import-wrapper" onClick={() => setImportTextOpen(true)}>{translations['admin.settings.import']}</div>
        {importTextOpen && <div className="import-text">
            <textarea value={importText} onChange={e => setImportText(e.target.value)} />
            <div className="controls">
                <Button className="process" text={translations['admin.settings.import.process']} onClick={importSettings} />
                <Button className="close" text={translations['admin.settings.import.close']} onClick={() => setImportTextOpen(false)} />
            </div>
        </div>}
        <div className="import-wrapper" onClick={() => setImportTranslationsAsSettingsTextOpen(true)}>{translations['admin.settings.import.translations-as-settings']}</div>
        {importTranslationsAsSettingsTextOpen && <div className="import-translations-as-settings-text">
            <Input value={importTranslationsAsSettingsGroup} onChange={setImportTranslationsAsSettingsGroup} placeholder={translations['admin.settings.import.translations-as-settings.group']} />
            <textarea value={importTranslationsAsSettingsText} onChange={e => setImportTranslationsAsSettingsText(e.target.value)} />
            <div className="controls">
                <Button className="process" text={translations['admin.settings.import.translations-as-settings.process']} onClick={importTranslationsAsSettings} />
                <Button className="close" text={translations['admin.settings.import.translations-as-settings.close']} onClick={() => setImportTranslationsAsSettingsTextOpen(false)} />
            </div>
        </div>}
    </div>
}

export default ExportImport