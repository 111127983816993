import './FilterItem.scss'

const FilterItem = ({ filter, remove }) => {
  return (
    <div className="filterItem">
      <div className="filterProps">
        <div>{filter.selectedProp.key}</div>
        <div>{filter.searchString}</div>
      </div>
      <div onClick={remove}>entfernen</div>
    </div>
  )
}

export default FilterItem
