export const set = (state, action) => {
    return {...state, settings: {
        items: action.payload,
        getGroup: function(group) {
            return this.items.filter(item => item.group === group)
        },
        getSingle: function(key) {
            return this.items.find(item => item.key === key)
        },
        getSizeDefinitions: function() { return this.items.sizeDefinitions}
    }}
}