import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { edit, set } from '../../../../action/tables'
import Modal from '../../../UI/Modal/Modal'
import Button from '../../../UI/Button/Button'
import './TableFilter.scss'
import Input from '../../../UI/Input/Input'
import { getColumns, getSortedColumns } from '../../../../helper/columns'
import _ from 'lodash'
import { getProps, getFilter } from '../interfaces/apiCalls'
import Checkbox from '../../../UI/Checkbox/Checkbox'
import { Wrapper } from './TableFilter.Styled.js'

const TableFilter = ({ showModal, handleClose, tableIndex }) => {
  const token = useSelector((state) => state.token)
  const tables = useSelector((state) => state.tables)
  const [allProps, setAllProps] = useState([])
  const [searchInput, setSearchInput] = useState('')
  const [filterToDisplay, setFilterToDisplay] = useState([])

  const filter = tables[tableIndex].filter

  const dispatch = useDispatch()

  useEffect(() => {
    const getAllProps = async () => {
      const props = await getProps(token)
      if (props) {
        setAllProps(props)
      }
    }

    const getFilterToDisplay = () => {
      const filteredProps = allProps.filter((el) =>
        el.key.toLowerCase().includes(searchInput.toLowerCase()),
      )
      setFilterToDisplay(filteredProps.map((prop) => prop.key))
    }

    if (searchInput !== '') getFilterToDisplay()
    if (searchInput === '') setFilterToDisplay([])

    if (allProps.length === 0 && token) getAllProps()
  }, [token, allProps, searchInput])

  const onChangeInput = (prop) => (value) => {
    const filterCopy = _.cloneDeep(filter)
    const tablesCopy = _.cloneDeep(tables)

    if (filterCopy.find((el) => el.prop.key === prop.key))
      filterCopy.find((el) => el.prop.key === prop.key).searchString = value
    else {
      filterCopy.push({ prop, searchString: value })
    }

    tablesCopy[tableIndex].filter = filterCopy.filter((el) => el.searchString !== '')

    dispatch(set(tablesCopy))
  }

  const onClickFilter = async () => {
    const tableCopy = _.cloneDeep(tables[tableIndex])
    dispatch(edit({ ...tableCopy, loading: true }, tableIndex))
    const results = await getFilter(token, filter)
    if (results) {
      const tableCopy = _.cloneDeep(tables[tableIndex])

      const columnsOfDB = tableCopy.columns
      const newGeneratedColumns = getColumns(results)

      const noUnusedColumns = columnsOfDB.filter((col) => newGeneratedColumns.includes(col))
      const columsToAppend = newGeneratedColumns.reduce((acc, el) => {
        if (!noUnusedColumns.includes(el)) {
          acc.push(el)
        }
        return acc
      }, [])

      tableCopy.columns = getSortedColumns([...noUnusedColumns, ...columsToAppend])

      tableCopy.items = results
      tableCopy.loading = false

      dispatch(edit(tableCopy, tableIndex))
    }
    handleClose()
  }

  const getPropsForMap = () => {
    if (Array.isArray(allProps)) {
      return allProps.filter(
        (prop) => filterToDisplay.includes(prop.key) || filterToDisplay.length === 0,
      )
    }

    return []
  }

  const onChangeMinMax = (prop, minOrMax) => (value) => {
    const filterCopy = _.cloneDeep(filter)
    const tablesCopy = _.cloneDeep(tables)

    if (filterCopy.find((el) => el.prop.key === prop.key))
      filterCopy.find((el) => el.prop.key === prop.key)[minOrMax] = value
    else {
      filterCopy.push({ prop, [minOrMax]: value })
    }

    tablesCopy[tableIndex].filter = filterCopy.filter((el) => el[minOrMax] !== '')

    dispatch(set(tablesCopy))
  }

  return (
    <Modal open={showModal} close={handleClose} title="Filter" size="xl">
      <div className="filterModalContent">
        <div className="search">
          <div className="inputWrapper">
            <Input
              value={searchInput}
              onChange={(value) => setSearchInput(value)}
              placeholder="Suchen"
            />
          </div>
        </div>
        <div className="filter">
          {getPropsForMap().map((prop) => (
            <div key={prop.key} className="item">
              {prop.type === 'Number' ? (
                <>
                  <Checkbox
                    text="existiert"
                    value={filter.find((el) => el.prop.key === prop.key)?.searchString === '.+'}
                    onChange={(checked) => onChangeInput(prop)(checked ? '.+' : '')}
                  />
                  <Wrapper>
                    <Input
                      type="number"
                      value={filter.find((el) => el.prop.key === prop.key)?.min || ''}
                      onChange={onChangeMinMax(prop, 'min')}
                      placeholder={`${prop.key} min`}
                    />
                    <Input
                      type="number"
                      value={filter.find((el) => el.prop.key === prop.key)?.max || ''}
                      onChange={onChangeMinMax(prop, 'max')}
                      placeholder={`${prop.key} max`}
                    />
                  </Wrapper>
                </>
              ) : (
                <>
                  <Checkbox
                    text="existiert"
                    value={filter.find((el) => el.prop.key === prop.key)?.searchString === '.+'}
                    onChange={(checked) => onChangeInput(prop)(checked ? '.+' : '')}
                  />
                  <Input
                    value={filter.find((el) => el.prop.key === prop.key)?.searchString || ''}
                    onChange={onChangeInput(prop)}
                    placeholder={prop.key}
                  />
                </>
              )}
            </div>
          ))}
        </div>
        <Button text="Filter Anwenden" onClick={onClickFilter} />
      </div>
    </Modal>
  )
}

export default TableFilter
