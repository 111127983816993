import styled from '@emotion/styled'

export const StyledHistory = styled.div`
  padding-top: 20px;
  border-top: 1px solid black;
  margin-top: 50px;
`

export const Headline = styled.div`
  font-size: 20px;
  font-weight: bold;
`
