const defaultState = {
  token: null,
  user: null,
  lang: null,
  langOptions: null,
  translations: null,
  settings: null,

  selectedProductForComparison: null,
  productLists: [
    {
      producer: null,
      filter: [],
      items: null,
    },
  ],
  variantList: [],

  filter: {},
  sorting: {
    key: '',
    direction: 0,
  },
  tables: [
    {
      compareSelectedProducts: [],

      columns: [],
      filter: [],
      sorting: {
        key: '',
        direction: 0,
      },
      loading: true,
      hiddenColumns: [],
      items: null,
    },
  ],
  compareReferenceProduct: null,
  similarSelectedProduct: null,
}

export default defaultState
