// import { useSelector } from 'react-redux'
import Layout from '../Layout/Layout'
// import CrawlerConfigurator from './CrawlerConfigurator/CrawlerConfigurator'
import Button from '../UI/Button/Button'
import { useState } from 'react'
// import FormattedResult from './FormattedResult/FormattedResult'
import './Configurator.scss'
import Tutorial from './Tutorial/Tutorial'
import KeyValueSelector from './KeyValueSelector/KeyValueSelector.js'
// import Result from './Result/Result.js'
import History from './History/History.js'
import fetch from '../../fetch'
import { useSelector } from 'react-redux'
import { triggerClientSideDownload } from '../../utility'
import Container from '../UI/Container/Container.js'

const Configurator = () => {
  // const [context, setContext] = useState(null)
  const [showTutorial, setShowTutorial] = useState(false)
  const token = useSelector((state) => state.token)

  // const onClickActiveContext = (context) => () => setContext(context)

  const exportCSV = async () => {
    const { status, csv } = await fetch('/props-cache/export', { token })
    if (status && csv) triggerClientSideDownload(csv, 'Eigenschaften.csv')
  }

  return (
    <Layout authRequired={true}>
      <Container>
        <div id="configurator">
          <Button text="Anleitung" onClick={() => setShowTutorial(true)} />
          <Tutorial showModal={showTutorial} handleClose={() => setShowTutorial(false)} />
          <Button text="Exportieren" onClick={exportCSV} />
          <KeyValueSelector />
          <History />
          {/* <div className="buttonWrapperContext">
          <Button
          text={translations['configurator.button-key.text']}
          onClick={onClickActiveContext('key')}
          />
          <Button
          text={translations['configurator.button-value.text']}
          onClick={onClickActiveContext('value')}
          />
          </div>
          
        {context && <CrawlerConfigurator context={context} />} */}

          {/* <FormattedResult /> */}
          {/* <Result /> */}
        </div>
      </Container>
    </Layout>
  )
}

export default Configurator
