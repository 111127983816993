import { useEffect, useState } from 'react'
import { buildURL } from '../../../../../../utility'

const LinkBuilder = ({ urls, producer, product, col }) => {
  const [links, setLinks] = useState(null)

  useEffect(() => {
    if (!links && urls) {
      if (Array.isArray(urls)) setLinks(urls)
      else setLinks([urls])
    }
  }, [urls, links])

  return (
    <div>
      {links &&
        Array.isArray(links) &&
        links.map((link) => (
          <a
            key={link}
            href={link.split('://')[0] === 'https' ? link : buildURL(producer.name, link)}
            target="_blank"
            rel="noreferrer"
            className={`${
              product.productComparisonResult
                ? Object.keys(product.productComparisonResult).includes(col) && product.productComparisonResult[col].value
                  ? 'green'
                  : Object.keys(product.productComparisonResult).includes(col) && product.productComparisonResult[col].value === null
                  ? ''
                  : 'red'
                : ''
            } external-link`}
          >
            {link.split('://')[0] === 'https' ? link : buildURL(producer.name, link)}
          </a>
        ))}
    </div>
  )
}

export default LinkBuilder
