import { useEffect, useState } from 'react'
import fetch from '../../../fetch'
import Checkbox from '../../UI/Checkbox/Checkbox'
import './List.scss'

const ProductList = () => {
  const [index, setIndex] = useState(0)
  const [limit, setLimit] = useState(50)
  const [products, setProducts] = useState(null)
  const [columns, setColumns] = useState([])
  const [excludedColumns, setExcludedColumns] = useState([])
  const [loadMore, setLoadMore] = useState(false)
  const [isLastPage, setIsLastPage] = useState(false)
  const [producer, setProducer] = useState(null)
  const [showProps, setShowProps] = useState(false)
  const [props, setProps] = useState(null)

  useEffect(() => {
    const initColumns = (products) => {
      const cols = products.reduce((acc, prod) => {
        Object.keys(prod).forEach((key) => {
          if (!acc.includes(key)) {
            acc.push(key)
          }
        })
        return acc
      }, columns)
      setColumns(cols)
    }

    const getProducts = async () => {
      const { status, products: newProducts } = await fetch('/product/get', {
        index,
        limit,
        producer,
      })
      if (status && newProducts) {
        initColumns(newProducts)
        if (!products) setProducts(newProducts)
        else setProducts([...products, ...newProducts])

        if (newProducts.length < limit) setIsLastPage(true)
        setLoadMore(false)
      }
    }

    if ((!products || loadMore) && producer) getProducts()
  }, [products, index, limit, columns, excludedColumns, loadMore, producer])

  const onClickCheckbox = (col) => (e) => {
    setExcludedColumns(e ? excludedColumns.filter((c) => c !== col) : [...excludedColumns, col])
  }

  const onClickLoadMore = () => {
    setIndex(index + 1)
    setLoadMore(true)
  }

  const onChangeSelect = (e) => {
    setLimit(Number.parseInt(e.target.selectedOptions[0].innerText))
    setProducts(null)
  }

  const onChangeProducer = (e) => {
    setProducer(e.target.selectedOptions[0].innerText)
    setProducts(null)
  }

  const getProps = async (uuid) => {
    const { status, props } = await fetch('/product/props/get', { producer, uuid })
    if (status && props) {
      setProps(props)
      setShowProps(true)
    }
  }

  if (!products && !columns) return <></>

  return (
    <div id="productList">
      <div className="producer">
        <select onChange={onChangeProducer}>
          <option>---bitte Wählen---</option>
          <option>Nexans</option>
          <option>Helukabel</option>
        </select>
      </div>
      <div>
        <select onChange={onChangeSelect}>
          <option>50</option>
          <option>10</option>
          <option>20</option>
          <option>100</option>
          <option>200</option>
          <option>500</option>
          <option>1000</option>
        </select>
      </div>
      <div className="excludedCols">
        {columns &&
          Array.isArray(columns) &&
          columns.map((col) => (
            <div>
              <Checkbox
                checked={!excludedColumns.includes(col)}
                onChange={onClickCheckbox(col)}
                text={col}
              />
            </div>
          ))}
      </div>
      <div className="columns">
        {columns &&
          Array.isArray(columns) &&
          columns
            .filter((c) => !excludedColumns.includes(c))
            .map((col) => {
              return <div>{col}</div>
            })}
      </div>
      <div className="products">
        {products &&
          Array.isArray(products) &&
          products.map((prod) => {
            return (
              <div className="productRow">
                <div className="columnWrapper">
                  {columns &&
                    Array.isArray(columns) &&
                    columns
                      .filter((c) => !excludedColumns.includes(c))
                      .map((col) => {
                        return <div>{prod[col]}</div>
                      })}
                  <div onClick={() => getProps(prod.uuid)}>show Props</div>
                </div>
                {showProps &&
                  props &&
                  Array.isArray(props) &&
                  props[0].productUUID === prod.uuid &&
                  props.map((prop) => (
                    <div className="propRow">
                      <div>{prop.key}</div>
                      <div>{prop.value}</div>
                    </div>
                  ))}
              </div>
            )
          })}
      </div>
      {!isLastPage && <div onClick={onClickLoadMore}>load more</div>}
    </div>
  )
}

export default ProductList
