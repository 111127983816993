import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom'
import DataProtection from './components/DataProtection/DataProtection'
import Imprint from './components/Imprint/Imprint'
import Login from './components/Auth/Login/Login'
import 'bootstrap/dist/css/bootstrap.min.css'
import Settings from './components/Settings/Settings'
import PropTranslations from './components/Products/Translations/Translations'
import DashboardRouting from './DashboardRouting'
import AdminRouting from './AdminRouting'
import NotFound from './components/NotFound/NotFound'
import { useSelector } from 'react-redux'
import ProductList from './components/Products/List/List'
import Translations from './components/Translations/Translations'
import CompareWrapper from './components/Products/CompareWrapper/CompareWrapper'
import Users from './components/Admin/Users/Users'
import History from './components/Products/History/History'
import Import from './components/Products/Import/Import'
import Searches from './components/Searches/Searches.js'

import Configurator from './components/Configurator/Configurator'

function App() {
  const user = useSelector((state) => state.user)
  return (
    <>
      <Settings>
        <Translations>
          <Router>
            <Switch>
              {/* LANDINGPAGE */}
              <Route exact={true} path="/">
                <Redirect to={!!user ? '/compare' : '/login'} />
                {/* <Home /> */}
                <ProductList />
              </Route>

              <Route exact path="/import">
                <Import />
              </Route>
              <Route exact path="/searches">
                <Searches />
              </Route>

              <Route exact path="/translations">
                <PropTranslations />
              </Route>
              <Route exact path="/compare">
                <CompareWrapper />
              </Route>
              <Route exact path="/configurator">
                <Configurator />
              </Route>
              <Route exact path="/users">
                <Users />
              </Route>
              <Route exact path="/history/:producer/:productUUID">
                <History />
              </Route>
              {/* ADMIN */}
              <Route exact={false} path="/admin" component={AdminRouting} />
              {/* LOGIN */}
              <Route exact={true} path="/login" component={Login} />

              {/* DASHBOARD - ROUTING */}
              <Route exact={false} path="/dashboard" component={DashboardRouting} />
              {/* IMPRINT */}
              <Route exact={true} path="/imprint" component={Imprint} />
              {/* DATA PROTECTION */}
              <Route exact={true} path="/data-protection" component={DataProtection} />
              <Route component={NotFound} />
            </Switch>
          </Router>
        </Translations>
      </Settings>
    </>
  )
}

export default App
