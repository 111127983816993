import "./RadioGroup.scss";
import FormLabel from "@mui/material/FormLabel";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import { getUUID } from "../../../utility";

const RG = (props) => {
  const id = getUUID();
  const { name, options, value, onChange } = props;
  const propIsMissing = () => {
    const requiredProps = ["name", "options", "onChange", "value"];
    return requiredProps.reduce(
      (acc, prop) => (acc === false ? false : props[prop] === undefined),
      true
    );
  };

  if (propIsMissing()) return <></>;
  return (
    <FormControl
      className="custom-MUI-radiogroup"
      variant="filled"
      fullWidth={true}
    >
        <FormLabel component="legend">{name}</FormLabel>
        <RadioGroup
          row
          name={id}
          value={value}
          onChange={e => onChange(e.target.value)}
        >
          {options.map((option) => (
            <FormControlLabel
              key={getUUID()}
              value={option.key}
              control={<Radio />}
              label={option.value}
            />
          ))}
        </RadioGroup>
    </FormControl>
  );
};

export default RG;
