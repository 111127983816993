import { useDispatch, useSelector } from 'react-redux'
import { set } from '../../../../action/tables'
import CB from '../../../UI/Checkbox/Checkbox'

import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'

import Modal from '../../../UI/Modal/Modal'
import './TableOptions.scss'

const TableOptions = ({ tableIndex, showModal, handleClose }) => {
  const dispatch = useDispatch()
  const tables = useSelector((state) => state.tables)
  const columns = tables[tableIndex].columns
  const hiddenColumns = tables[tableIndex].hiddenColumns

  const changeOrder = (direction, col) => () => {
    const indexOfCol = columns.indexOf(col)
    const columnsCopy = [...columns]

    if (direction === 'prev') {
      columnsCopy[indexOfCol] = columnsCopy[indexOfCol - 1]
      columnsCopy[indexOfCol - 1] = col
    } else if (direction === 'next') {
      columnsCopy[indexOfCol] = columnsCopy[indexOfCol + 1]
      columnsCopy[indexOfCol + 1] = col
    }
    const tablesCopy = [...tables]

    tablesCopy[tableIndex].columns = columnsCopy
    dispatch(set(tablesCopy))
  }

  const changeVisibility = (col) => () => {
    const tablesCopy = [...tables]
    tablesCopy[tableIndex].hiddenColumns = hiddenColumns.includes(col)
      ? hiddenColumns.filter((el) => el !== col)
      : [...hiddenColumns, col]
    dispatch(set(tablesCopy))
  }

  return (
    <Modal open={showModal} close={handleClose} title="Tabellenoptionen">
      <div id="tableOptions">
        {columns.map((col, i) => (
          <div className="columnRow" key={col}>
            <div className="arrows">
              {i !== 0 && (
                <div onClick={changeOrder('prev', col)}>
                  <ArrowUpwardIcon />
                </div>
              )}
              {i < columns.length - 1 && (
                <div onClick={changeOrder('next', col)}>
                  <ArrowDownwardIcon />
                </div>
              )}
            </div>
            <CB text={''} checked={!hiddenColumns.includes(col)} onChange={changeVisibility(col)} />
            <div>{col}</div>
          </div>
        ))}
      </div>
    </Modal>
  )
}

export default TableOptions
