import { useDispatch, useSelector } from 'react-redux'
import { add, set } from '../../../action/tables'

import Layout from '../../Layout/Layout'
import Table from '../Table/Table'
import defaultState from '../../../reducer/defaultState'
import IconNewTable from '../../../assets/image/Icons/Icon-neue Tabelle.png'
import IconSave from '../../../assets/image/Icons/Icon-Filterauswahl speichern.png'
import IconOpenSearchList from '../../../assets/image/Icons/open-search-list.png'
import Modal from '../../UI/Modal/Modal'
import './CompareWrapper.scss'
import Similar from './Similar/Similar'
import fetch from '../../../fetch'
import { useEffect, useState } from 'react'
import _ from 'lodash'
import Input from '../../UI/Input/Input'
import Button from '../../UI/Button/Button'
import {
  setCompareReferenceProduct,
  setSimilarSelectedProduct,
} from '../../../action/selectedProduct'
import Searches from '../../Searches/Searches'

const CompareWrapper = () => {
  const similarSelectedProduct = useSelector((state) => state.similarSelectedProduct)
  const compareReferenceProduct = useSelector((state) => state.compareReferenceProduct)
  const token = useSelector((state) => state.token)

  const tables = useSelector((state) => state.tables)

  const [showSaveSearch, setShowSaveSearch] = useState(false)
  const [searchName, setSearchName] = useState('')
  const [error, setError] = useState('')
  const [showSearches, setShowSearches] = useState(false)
  const [resetEnabled, setResetEnabled] = useState(false)

  const dispatch = useDispatch()

  useEffect(() => {
    for (const table of tables) {
      if (!resetEnabled) {
        if (
          table.filter.length !== defaultState.tables[0].filter.length ||
          similarSelectedProduct !== defaultState.similarSelectedProduct ||
          compareReferenceProduct !== defaultState.compareReferenceProduct
        ) {
          setResetEnabled(true)
        }
      }
    }
  }, [tables, compareReferenceProduct, similarSelectedProduct, resetEnabled])

  const addTable = () => {
    dispatch(
      add({
        columns: [],
        sorting: {
          key: '',
          direction: 0,
        },
        compareSelectedProducts: [],
        filter: [],
        hiddenColumns: [],
        items: null,
      }),
    )
  }

  const saveSearch = async () => {
    const tablesCopy = _.cloneDeep(tables)
    for (const table of tablesCopy) {
      delete table.items
    }

    const { status } = await fetch('/search/save', { token, tables: tablesCopy, name: searchName })
    if (status) {
      setShowSaveSearch(false)
      setError('')
    } else {
      setError('Konnte nicht gespeichert werden')
    }
  }

  const onClickReset = () => {
    setResetEnabled(false)
    dispatch(set(defaultState.tables))
    dispatch(setCompareReferenceProduct(defaultState.compareReferenceProduct))
    dispatch(setSimilarSelectedProduct(defaultState.similarSelectedProduct))
  }

  return (
    <Layout authRequired={true} search={true}>
      <div className="btnResetWrapper">
        <Button
          onClick={onClickReset}
          text="Zurücksetzen"
          disabled={!resetEnabled}
          fullWidth={false}
        />
      </div>
      <div className="saveSearchWrapper">
        <div className="saveSearch">
          <div className="imageWrapper">
            <img src={IconSave} alt="saveSearchIcon" />
          </div>
          <div onClick={() => setShowSaveSearch(true)} className="label">
            Suche Speichern
          </div>
        </div>
      </div>
      <div className="saveSearchWrapper">
        <div className="saveSearch">
          <div className="imageWrapperSavedSearches">
            <img src={IconOpenSearchList} alt="saveSearchIcon" />
          </div>
          <div onClick={() => setShowSearches(true)} className="label">
            Gespeicherte Suchen
          </div>
        </div>
      </div>
      <Modal open={showSearches} close={() => setShowSearches(false)} title="Suchen">
        <Searches close={() => setShowSearches(false)} />
      </Modal>
      <Modal open={showSaveSearch} close={() => setShowSaveSearch(false)} title="Suche Speichern">
        <div className="searchSaveModalContent">
          <Input value={searchName} onChange={(e) => setSearchName(e)} placeholder="Name" />
          <Button onClick={saveSearch} text="Suche Speichern" />
          <div>{error}</div>
        </div>
      </Modal>
      {Array.isArray(tables) && tables.map((table, i) => <Table tableIndex={i} key={i} />)}
      <div className="newTableWrapper">
        <img onClick={addTable} src={IconNewTable} alt="new Table" title="Tabelle hinzufügen" />
      </div>
      <Similar similarSelectedProduct={similarSelectedProduct} />
    </Layout>
  )
}

export default CompareWrapper
