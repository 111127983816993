import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setCompareReferenceProduct } from '../../../../action/selectedProduct'
import { add, edit } from '../../../../action/tables'
import fetch from '../../../../fetch'
import { getColumns } from '../../../../helper/columns'
import usePrevious from '../../../../helper/usePrevious'

const Similar = ({ similarSelectedProduct }) => {
  const token = useSelector((state) => state.token)
  const tables = useSelector((state) => state.tables)
  const dispatch = useDispatch()

  const previousSimilarProduct = usePrevious(similarSelectedProduct)

  useEffect(() => {
    const getSimilarProducts = async () => {
      const index = tables.length
      dispatch(
        add({
          columns: [],
          filter: [],
          compareSelectedProducts: [],
          sorting: {
            key: '',
            direction: 0,
          },
          loading: true,
          hiddenColumns: [],
          items: null,
        }),
      )
      dispatch(
        edit(
          {
            ...tables[0],
            items: [tables[0]?.items?.find((el) => el.uuid === similarSelectedProduct.uuid)],
          },
          0,
        ),
      )
      dispatch(setCompareReferenceProduct(similarSelectedProduct))
      const { status, products, count } = await fetch('/product/similar', {
        product: similarSelectedProduct,
        token,
      })
      if (status && products) {
        dispatch(
          edit(
            {
              columns: getColumns(products),
              // compareSelectedProducts: [],
              sorting: {
                key: '',
                direction: 0,
              },
              loading: false,
              hiddenColumns: [],
              filter: [],
              items: products,
              compareSelectedProducts: products,
              count,
            },
            index,
          ),
        )
      }
    }

    if (similarSelectedProduct && similarSelectedProduct !== previousSimilarProduct)
      getSimilarProducts()
  }, [similarSelectedProduct, token, dispatch, previousSimilarProduct, tables])

  return <></>
}

export default Similar
