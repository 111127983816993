import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import fetch, { fileUploadToBackend } from '../../../fetch'
import { triggerClientSideDownload } from '../../../utility'

import Layout from '../../Layout/Layout'
import Dropdown from '../../UI/Dropdown/Dropdown'
import Input from '../../UI/Input/Input'
import Button from '../../UI/Button/Button'

import './Import.scss'

const productColumns = ['title', 'description', 'url', 'resourcesURLs', 'key']

const Import = () => {
  const [providerList, setProviderList] = useState(null)
  const [selectedProvider, setSelectedProvider] = useState('')
  const [newProviderName, setNewProviderName] = useState('')

  const [selectedFile, setSelectedFile] = useState(null)

  const [csvTemplate, setCsvTemplate] = useState(null)

  const [props, setProps] = useState(null)

  const [reloadProps, setReloadProps] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [uploadFinished, setUploadFinished] = useState(false)
  const [error, setError] = useState(false)

  const token = useSelector((state) => state.token)

  useEffect(() => {
    const getProvider = async () => {
      const { status, result } = await fetch('/provider/get', { token })
      if (status && result) setProviderList(result)
    }

    const getProps = async () => {
      const producer = providerList.find((p) => p.uuid === selectedProvider)
      const { status, props } = await fetch('/props/get', { producer, getBoth: true, token })
      if (status && props) {
        setProps(props)
        setReloadProps(false)
      }
    }

    const buildTemplate = () => {
      let string = productColumns.join(';')
      string = string + ';'
      string = string + props.map((p) => p.key).join(';')
      string = string + '\n'
      setCsvTemplate(string)
    }

    // if (selectedFile) fileUploadToBackend(token, '/import/upload', selectedFile, { providerUUID: selectedProvider })

    if (props && !csvTemplate) buildTemplate()
    if (!providerList && token) getProvider()
    if (selectedProvider && selectedProvider !== 'new' && providerList && (!props || reloadProps)) getProps()
  }, [token, providerList, selectedProvider, props, csvTemplate, selectedFile, reloadProps])

  const onClickCreateProducer = async () => {
    if (newProviderName !== '') {
      const { status, result } = await fetch('/provider/save', { token, name: newProviderName })
      if (status && result) {
        const copy = [...providerList]
        copy.push(result)
        setProviderList(copy)
        setSelectedProvider('')
      }
    }
  }

  const onClickDownload = () => {
    if (csvTemplate) triggerClientSideDownload(csvTemplate, 'template.csv')
  }

  const onClickUpload = async () => {
    if (selectedFile && selectedProvider) {
      setIsLoading(true)
      const { status } = await fileUploadToBackend(token, '/import/upload', selectedFile, { providerUUID: selectedProvider })
      setIsLoading(false)
      if (status) setUploadFinished(true)
      else setError('Fehler beim Import')
    }
  }

  const onChangeProvider = (e) => {
    setSelectedProvider(e)
    if (e !== selectedProvider) {
      setReloadProps(true)
    }
  }

  return (
    <Layout authRequired={true}>
      <div id="import">
        {Array.isArray(providerList) && (
          <Dropdown
            name="Hersteller auswählen"
            enableEmptySelection={false}
            value={selectedProvider}
            onChange={onChangeProvider}
            options={providerList
              .reduce(
                (acc, p) => {
                  acc.push({ key: p.uuid, value: p.name })
                  return acc
                },
                [{ key: 'new', value: '+ Hersteller hinzufügen' }],
              )
              .reverse()}
          />
        )}

        {selectedProvider === 'new' && (
          <div className="newProvider">
            <Input type="text" value={newProviderName} onChange={(e) => setNewProviderName(e)} placeholder="Neuer Herstellername" />
            <Button text="Speichern" onClick={onClickCreateProducer} fullWidth={false} />
          </div>
        )}

        <div className="description">
          <div>Generierung einer Vorlage zum Importieren von Produkten zum ausgewählten Hersteller</div>
          <div>Bereitgestellt wird die Vorlage in Form einer CSV-Datei (separiert durch den Zeichen ";" und muss mit diesem Seperator in Excel auch wieder abgespeichert werden)</div>
        </div>
        {selectedProvider && selectedProvider !== 'new' && selectedProvider !== 'choose' && (
          <div className="btnWrapper">
            <div className="left">
              <div onClick={onClickDownload} className="btn">
                Vorlage herunterladen
              </div>
            </div>
            <div className="right">
              <input className="importFile" type="file" onChange={(e) => setSelectedFile(e.target.files[0])} />
              <div className="btn" onClick={onClickUpload}>
                Importieren
              </div>
            </div>
          </div>
        )}
        {isLoading && <div>Upload läuft...</div>}
        {uploadFinished && <div>Import abgeschlossen!</div>}
        {error && <div>{error}</div>}
      </div>
    </Layout>
  )
}

export default Import
