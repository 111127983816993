import './Header.scss'
import { useHistory } from 'react-router-dom'
import headerLogo from '../../../assets/image/logo.png'
import { useEffect, useState } from 'react'
import { projectName } from '../../../utility'
import Search from '../Search/Search'

import IconBenutzerAccount from '../../../assets/image/Icons/Icon-Benutzer-Account.png'
import { useDispatch, useSelector } from 'react-redux'
// import Modal from '../../UI/Modal/Modal'
import Cookies from 'universal-cookie'
import { setUser, setUserToken } from '../../../action/user'
// import Searches from '../../Searches/Searches'

import { useLocation } from 'react-router-dom'

const Header = ({ search }) => {
  const history = useHistory()
  const [showMenu, setShowMenu] = useState(false)
  // const [showSearchesModal, setShowSearchesModal] = useState(false)
  const user = useSelector((state) => state.user)
  const cookies = new Cookies()
  const dispatch = useDispatch()

  const location = useLocation()
  const currentPage = location.pathname.slice(1, location.pathname.length)

  const handleScroll = () => {
    document.querySelector('#header').className = window.scrollY > 80 ? 'user-scrolled' : ''
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  })

  const goHome = (e) => {
    e.preventDefault()
    window.scrollTo(0, 0)
    history.push('/')
  }

  const onClickLogout = () => {
    cookies.remove('token', { path: '/' })
    dispatch(setUser(null))
    dispatch(setUserToken(null))
    history.push('/')
  }

  // const onClickSavedSearches = () => setShowSearchesModal(true)

  const navigateTo = (location) => history.push(`/${location}`)

  return (
    <div id="header">
      <div className="desktop">
        <div className="logo" onClick={goHome}>
          <img src={headerLogo} alt={projectName} />
        </div>
        {user && (
          <>
            {search && <Search />}
            <div className="accountLogoWrapper">
              <img
                onClick={() => setShowMenu(!showMenu)}
                src={IconBenutzerAccount}
                alt="account"
                title="Menü"
              />
              {showMenu && (
                <div className="menu" onClick={() => setShowMenu(false)}>
                  <div
                    className={`menuItem ${currentPage === 'compare' ? 'selected' : ''}`}
                    onClick={() => navigateTo('compare')}
                  >
                    Suchmaske
                  </div>
                  {user.permissions === 'admin' && (
                    <div
                      className={`menuItem ${currentPage === 'admin/users' ? 'selected' : ''}`}
                      onClick={() => navigateTo('admin/users')}
                    >
                      User Management
                    </div>
                  )}
                  {user.permissions === 'admin' && (
                    <div
                      className={`menuItem ${currentPage === 'configurator' ? 'selected' : ''}`}
                      onClick={() => navigateTo('configurator')}
                    >
                      Regex config
                    </div>
                  )}
                  {/* <div className={`menuItem`} onClick={onClickSavedSearches}>
                    Gespeicherte Suchen
                  </div> */}
                  <div className={`menuItem`} onClick={onClickLogout}>
                    Abmelden
                  </div>
                </div>
              )}
            </div>
          </>
        )}
      </div>
      {/* <Modal
        open={showSearchesModal}
        close={() => setShowSearchesModal(false)}
        title="Gespeicherte Suchen"
      >
        <Searches close={() => setShowSearchesModal(false)} />
      </Modal> */}
    </div>
  )
}

export default Header
