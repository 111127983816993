import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import './Table.scss'
import { edit, removeIndex } from '../../../action/tables'
import usePrevious from '../../../helper/usePrevious'
import TableHeader from './TableHeader/TableHeader'

import LoadingIcon from '../../../assets/image/Icons/loading.gif'
import TableBody from './TableBody/TableBody'
import * as apiCalls from './interfaces/apiCalls'
import CableIconBlue from '../../../assets/image/Icons/Icon-Suchergebnisse Bayka.png'
import TableCloseIcon from '../../../assets/image/Icons/Icon-Tabelle schliesen.png'
import { getColumns, getSortedColumns } from '../../../helper/columns'

import _ from 'lodash'
import TableOptionsRow from './TableOptionsRow/TableOptionsRow'

const limit = 25

const Table = ({ tableIndex }) => {
  const table = useSelector((state) => state.tables[tableIndex])
  const tables = useSelector((state) => state.tables)
  const token = useSelector((state) => state.token)
  const translations = useSelector((state) => state.translations)
  const similarSelectedProduct = useSelector((state) => state.similarSelectedProduct)

  const { columns, hiddenColumns, filter, loading, items: data } = table

  const [pageIndex, setPageIndex] = useState(0)
  const [isLastPage, setIsLastPage] = useState(false)

  const [columnsToDisplay, setColumnsToDisplay] = useState([])

  const [fullscreen, setFullscreen] = useState(false)

  const prevPageIndex = usePrevious(pageIndex)
  const prevFilter = usePrevious(filter)
  const dispatch = useDispatch()

  useEffect(() => {
    const getProducts = async () => {
      const { products: newProducts, count } = await apiCalls.getProducts(limit, pageIndex, token)
      if (newProducts) {
        if (newProducts.length < limit) setIsLastPage(true)
        const tableCopy = _.cloneDeep(table)
        const lastIndexBeforNewElements = tableCopy?.items?.length - 1

        tableCopy.items = pageIndex === 0 ? newProducts : [...data, ...newProducts]

        const products = tableCopy.items

        const columns = getColumns(products)

        const sortedColumns = getSortedColumns(columns)

        tableCopy.columns = sortedColumns
        tableCopy.loading = false
        tableCopy.count = count

        dispatch(edit(tableCopy, tableIndex))

        if (pageIndex !== 0) {
          const tableBody = document.getElementById(`tableBody-${tableIndex}`)
          const scrollHeight = tableBody.scrollHeight
          const scrollHeightPerRow = scrollHeight / tableCopy.items.length
          const scrollTop = scrollHeightPerRow * lastIndexBeforNewElements
          tableBody.scrollTop = scrollTop
        }
      }
    }

    const onClickFilter = async () => {
      const tableCopy = _.cloneDeep(table)
      // dispatch(edit({ ...tableCopy, loading: true }, tableIndex))
      const results = await apiCalls.getFilter(token, filter)
      if (results) {
        tableCopy.items = results
        tableCopy.loading = false

        const columnsOfDB = tableCopy.columns
        const newGeneratedColumns = getColumns(results)

        const noUnusedColumns = columnsOfDB.filter((col) => newGeneratedColumns.includes(col))
        const columsToAppend = newGeneratedColumns.reduce((acc, el) => {
          if (!noUnusedColumns.includes(el)) {
            acc.push(el)
          }
          return acc
        }, [])

        tableCopy.columns = [...noUnusedColumns, ...columsToAppend]

        dispatch(edit(tableCopy, tableIndex))
      }
    }

    const header = document.getElementsByClassName('tableHeader')[0]
    const body = document.getElementsByClassName('tableBody')[0]

    if (header && body) {
      header.addEventListener('scroll', (e) => (body.scrollLeft = e.target.scrollLeft))
      body.addEventListener('scroll', (e) => (header.scrollLeft = e.target.scrollLeft))
    }

    setColumnsToDisplay([...columns.filter((el) => !hiddenColumns.includes(el))])

    if (filter.length > 0 && data === null) onClickFilter()

    if (token)
      if (
        (data === null && filter.length === 0 && !similarSelectedProduct) ||
        prevPageIndex < pageIndex
      )
        getProducts()

    return () => {
      const header = document.getElementsByClassName('tableHeader')[0]
      const body = document.getElementsByClassName('tableBody')[0]
      if (header && body) {
        header.removeEventListener('scroll', () => {})
        body.removeEventListener('scroll', () => {})
      }
    }
  }, [
    dispatch,
    data,
    table,
    prevFilter,
    tableIndex,
    pageIndex,
    prevPageIndex,
    columns,
    hiddenColumns,
    token,
    filter,
    similarSelectedProduct,
  ])

  const onClickCloseTable = () => dispatch(removeIndex(tableIndex))

  return (
    <div className={`productTableWrapper ${fullscreen ? 'fullscreen' : ''}`}>
      <div className={`preOptions ${fullscreen ? 'fullscreen' : ''}`}>
        <div className="countOfItems">
          <div className="iconWrapper">
            <img src={CableIconBlue} alt="cableIcon" />
          </div>
          <div>{tables[tableIndex].count} Suchergebnisse</div>
        </div>
        {tableIndex !== 0 && (
          <div className="closeTableWrapper">
            <img onClick={onClickCloseTable} src={TableCloseIcon} alt="closeTable" />
          </div>
        )}
      </div>

      <TableOptionsRow
        tableIndex={tableIndex}
        setFullscreen={setFullscreen}
        fullscreen={fullscreen}
      />

      <div className={`productTable ${fullscreen ? 'fullscreen' : ''}`} id={`table-${tableIndex}`}>
        <div className="inner-container">
          <TableHeader tableIndex={tableIndex} columnsToDisplay={columnsToDisplay} />
          {!loading && data && (
            <TableBody
              table={table}
              fullscreen={fullscreen}
              tableIndex={tableIndex}
              isLastPage={isLastPage}
              setPageIndex={setPageIndex}
              pageIndex={pageIndex}
              columnsToDisplay={columnsToDisplay}
            />
          )}
          {(loading || data === null) && (
            <div className="loadingWrapper">
              <img src={LoadingIcon} alt="loading ..." />
            </div>
          )}
        </div>
      </div>
      <div>{translations['table.help.scroll']}</div>
      <div>{translations['table.help.zoom']}</div>
    </div>
  )
}

export default Table
